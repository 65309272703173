<template>
  <div>
    <div class="fixed-menu">
      <b-form-checkbox switch v-model="editMode" class="mr-n2">
       {{ $t('NEW_DASHBOARD.EDIT_MODE') }}
      </b-form-checkbox>
      <b-button
        v-if="editMode"
        @click="saveLayout"
        variant="primary"
        class="ml-2"
      >
        {{ $t('COMMON.SAVE') }}
      </b-button>
    </div>
    <grid-layout
      ref="gridLayout"
      :layout="widgets"
      :col-num="12"
      :row-height="30"
      :is-draggable="editMode"
      :is-resizable="editMode"
      :is-mirrored="false"
      :vertical-compact="true"
      :use-css-transforms="true"
      @layout-updated="onLayoutUpdated"
      @drag-start="onDragStart"
      @drag-stop="onDragStop"
      @resize-start="onResizeStart"
      @resize-stop="onResizeStop"
    >
      <grid-item
        v-for="widget in validWidgets"
        :ref="'grid-item' + widget.i"
        :key="widget.i"
        :is-draggable="editMode"
        :is-resizable="editMode"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        :min-w="widget.isLep ? 12 : 6"
        :max-w="12"
        :max-h="40"
        :min-h="widget.isLep ? 30 : 20"
        class="grid-item-container"
        @resize="(event) => onResize(event, widget)"
      >
        <ChartWrapper
          :editMode="editMode"
          :remove="removeWidget"
          :widget="widget"
          :isForecastWidget="isForecastWidget(widget)"
          @resize="onResize"
          @update-widget="$emit('update-widget', $event)"
        ></ChartWrapper>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import ChartWrapper from "./charts/ChartWrapper";

const { GridLayout, GridItem } = VueGridLayout;

export default {
  components: {
    GridLayout,
    GridItem,
    ChartWrapper,
  },
  data() {
    return {
      editMode: false,
      pendingLayout: [], 
    };
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
    widgets: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(widget => {
          return (
            Number.isInteger(widget.x) &&
            Number.isInteger(widget.y) &&
            Number.isInteger(widget.w) &&
            Number.isInteger(widget.h) &&
            widget.x >= 0 &&
            widget.y >= 0 &&
            widget.w > 0 &&
            widget.h > 0
          );
        });
      },
    },
  },
  computed: {
    validWidgets() {
      return this.validateWidgets(this.widgets);
    }
  },
  mounted() {
    this.ensureUniqueWidgetIds(this.widgets);
  },
  methods: {
    onLayoutUpdated(newLayout) {
      this.pendingLayout = newLayout;
    },
    isForecastWidget(widget) {
      let device = this.devices.find((device) => device.id === widget.deviceId);
      if (device) {
        return device.forecast;
      }
      return false;
    },
    validateWidgets(widgets) {
      return widgets.filter(widget => {
        const isValid =
          widget.i &&
          Number.isInteger(widget.x) &&
          Number.isInteger(widget.y) &&
          Number.isInteger(widget.w) &&
          Number.isInteger(widget.h) &&
          widget.x >= 0 &&
          widget.y >= 0 &&
          widget.w > 0 &&
          widget.h > 0;
        if (!isValid) {
          console.error(`Неверный виджет: ${JSON.stringify(widget)}`);
        }
        return isValid;
      });
    },
    ensureUniqueWidgetIds(widgets) {
      const idSet = new Set();
      widgets.forEach(widget => {
        if (idSet.has(widget.i)) {
          console.warn(`Дублирующийся ID найден: ${widget.i}. Генерация нового ID.`);
          widget.i = this.generateUniqueId(); 
        }
        idSet.add(widget.i);
      });
    },
    generateUniqueId() {
      return `widget-${Math.random().toString(36).substr(2, 9)}`;
    },
    validateLayout(layout) {
      const invalidItems = layout.filter(item => {
        return (
          !Number.isInteger(item.x) ||
          !Number.isInteger(item.y) ||
          !Number.isInteger(item.w) ||
          !Number.isInteger(item.h) ||
          item.x < 0 ||
          item.y < 0 ||
          item.w <= 0 ||
          item.h <= 0
        );
      });

      if (invalidItems.length > 0) {
        console.error("Неверные элементы layout:", invalidItems);
        return false;
      }
      return true;
    },
    fixLayout(layout) {
      return layout.map(item => {
        return {
          ...item,
          x: Math.max(0, item.x || 0),
          y: Math.max(0, item.y || 0),
          w: Math.max(6, item.w || 6), // Минимальная ширина 6
          h: Math.max(20, item.h || 20), // Минимальная высота 20
        };
      });
    },
    saveLayout() {
      if (this.validateLayout(this.pendingLayout)) {
        const fixedLayout = this.fixLayout(this.pendingLayout);
        this.$emit("update-widgets", fixedLayout);
        this.$emit("save-widgets");
      } else {
        console.error("Неверная конфигурация layout. Сохранение отменено.");
      }
    },
    onDragStart(event) {
      console.log("Drag start event:", event);
    },
    onDragStop(event) {
      console.log("Drag stop event:", event);
    },
    onResizeStart(event) {
      console.log("Resize start event:", event);
    },
    onResizeStop(event) {
      console.log("Resize stop event:", event);
    },
    onResize(event, widget) {
      const el = this.$refs[`grid-item-${widget.i}`];
      if (el) {
        const { offsetWidth, offsetHeight } = el.$el || {};
        console.log(
          `После изменения размера - Ширина: ${offsetWidth}, Высота: ${offsetHeight}`
        );
      }
    },
    removeWidget(widgetId) {
      this.$emit("remove-widget", widgetId);
    },
  },
};
</script>

<style scoped>
.grid-item-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.1);
  background-color: #ffffff;
  border: 1px solid #f2f3f8;
  border-radius: 20px;
  margin: 0;
  height: 100%;
  padding: 20px;
}
.fixed-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  border-radius: 10px;
  top: -26px;
  z-index: 1;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
