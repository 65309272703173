<template>
  <div class="table-container">
    <div class="table-header" ref="tableHeader">
      <table :class="cssClasses">
        <thead>
          <tr>
            <th>Дата</th>
            <th v-for="header in headers" :key="header">{{ header }}</th>
          </tr>
        </thead>
      </table>
    </div>
    <div
      class="table-body"
      ref="tableBody"
      :style="bodyStyles"
      @scroll="syncScroll"
    >
      <table :class="cssClasses">
        <tbody>
          <tr v-for="(row, rowIndex) in formattedRows" :key="rowIndex">
            <td>{{ row.date }}</td>
            <td v-for="(value, cellIndex) in row.values" :key="cellIndex">
              {{ value ? Number(value.toFixed(4)).toString() : 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="table-footer"
      ref="tableFooter"
      style="overflow-y: scroll"
      @scroll="syncScroll"
    >
      <table :class="cssClasses">
        <thead>
          <tr>
            <th>Вычисления</th>
            <th v-for="header in headers" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>Сумма</td>
            <td v-for="(sum, index) in sums" :key="index">
              {{ sum ? Number(sum.toFixed(4)).toString() : 0 }}
            </td>
          </tr>
          <tr>
            <td>Среднее ариф.</td>
            <td
              v-for="(arithmetic_average, index) in arithmetic_averages"
              :key="index"
            >
              {{
                arithmetic_average
                  ? Number(arithmetic_average.toFixed(4)).toString()
                  : 0
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableComponent",
  props: {
    rawData: {
      type: Array,
      required: true,
    },
    cssClasses: {
      type: String,
      default: "",
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        labels: [],
        datasets: [],
      },
      sums: [],
      arithmetic_averages: [],
    };
  },
  computed: {
    headers() {
      return this.data.datasets.map((dataset) => dataset.label);
    },
    formattedRows() {
      return this.data.labels.map((label, index) => {
        return {
          date: label,
          values: this.data.datasets.map((dataset) =>
            dataset.data[index] !== undefined ? dataset.data[index] : "_"
          ),
        };
      });
    },
    bodyStyles() {
      return {
        height: `${this.widget.h * 13}px`,
        overflowY: "scroll",
        overflowX: "auto",
      };
    },
  },
  watch: {
    rawData: {
      handler(newData) {
        this.generateTableData(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    generateTableData(data) {
      const reversedData = data.slice().reverse();
      const labels = reversedData.map((item) => {
        const date = new Date(item.date);
        return date.toLocaleString("ru-RU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      });
      const datasets = [];
      const colorPalette = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
      ];
      let colorIndex = 0;

      if (reversedData.length > 0) {
        const parameters = Object.keys(reversedData[0].parameterValues);
        parameters.forEach((param) => {
          const paramData = reversedData.map(
            (item) => item.parameterValues[param]
          );
          datasets.push({
            label: param.toUpperCase(),
            data: paramData,
            borderColor: colorPalette[colorIndex % colorPalette.length],
            backgroundColor: colorPalette[colorIndex % colorPalette.length],
            fill: false,
          });
          colorIndex++;
        });
      }

      this.data = {
        labels,
        datasets,
      };
      this.calculateSums();
      this.calculateArithmeticAverages();
    },

    calculateSums() {
      this.sums = this.data.datasets.map((dataset) =>
        dataset.data.reduce(
          (sum, value) => sum + (value !== null ? value : 0),
          0
        )
      );
    },
    calculateArithmeticAverages() {
      this.arithmetic_averages = this.data.datasets.map((dataset) => {
        const length = dataset.data.length;
        return dataset.data.reduce(
          (sum, value) => sum + (value !== null ? value : 0) / length,
          0
        );
      });
    },
    syncScroll() {
      const tableHeader = this.$refs.tableHeader;
      const tableBody = this.$refs.tableBody;
      const tableFooter = this.$refs.tableFooter;
      tableHeader.scrollLeft = tableBody.scrollLeft;
      tableFooter.scrollLeft = tableBody.scrollLeft;
    },
  },
  mounted() {
    this.generateTableData(this.rawData);
  },
};
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow: hidden;
}

.table-header,
.table-footer {
  overflow: hidden;
}

.table-body {
  overflow-y: scroll;
  overflow-x: auto;
}

.table-container ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-container ::-webkit-scrollbar-thumb {
  background: #64cff2;
  border-radius: 10px;
}

.table-container ::-webkit-scrollbar-thumb:hover {
  background: #23bcf0;
}

.table-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  color: #02151b;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 150px;
  max-width: 150px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}
</style>
