<template>
  <div>
    <MeasurementsList 
      v-if="!selectedSensorId" 
      :widget="widget" 
      :start-date="startDate"
      :end-date="endDate"
      @select="handleSelect"
      :filters="filters"
    />
    <MeasurementDetail 
      v-if="selectedSensorId" 
      :sensor-id="selectedSensorId" 
      :widget="widget" 
      @back="selectedSensorId = null" 
    />
  </div>
</template>

<script>
import MeasurementsList from "./MeasurementsList.vue";
import MeasurementDetail from "./MeasurementDetail.vue";

export default {
  components: {
    MeasurementsList,
    MeasurementDetail
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedSensorId: null
    };
  },
  methods: {
    handleSelect(sensorId) {
      this.selectedSensorId = sensorId;
    }
  }
};
</script>

