<template>
  <div :style="wrpStyles">
    <div class="bar-chart-options-wrapper d-flex align-items-center">
      <input
        type="number"
        v-model.number="minThreshold"
        @input="generateChartData(rawData)"
        placeholder="Введите минимальный порог"
        class="input-field form-control me-3"
        style="height: 38px"
      />

      <div
        v-for="(sum, param) in totalAboveThreshold"
        :key="param"
        class="bar-chart-options d-flex me-3 text-center"
        style="height: 38px"
      >
        <p class="m-0 ">
          {{ $t('NEW_DASHBOARD.SAT_AMOUNT') }} <strong>{{ param }}</strong> :
          <strong>{{ sum.sat.toFixed(2) }}</strong>
        </p>
        <p class="m-0 ml-2">
          {{ $t('NEW_DASHBOARD.SET_AMOUNT') }} <strong>{{ param }}</strong> :
          <strong>{{ sum.set.toFixed(2) }}</strong>
        </p>
      </div>

      <div class="sensor-select ml-3 min-w-100px">
        <b-form-select
          class="form-select"
          v-model="selectedSensor"
          :options="soilSensors"
          @change="handleSensorChange"
          style="height: 38px"
        >
        </b-form-select>
      </div>
    </div>

    <BarChartGenerator
      :chartOptions="chartOptions"
      :chartData="chartData"
      :styles="myStyles"
      ref="barChart"
      class="chart"
    />
    <BarChartGenerator
      :chartOptions="SETChartOptions"
      :chartData="SETChartData"
      :styles="myStyles"
      ref="SETBarChart"
      class="chart"
    />
  </div>
</template>

<script>
import { Bar as BarChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  Plugin,
} from "chart.js";

const thresholdLinePlugin = {
  id: "thresholdLine",
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { top, right, bottom, left },
      scales: { y },
    } = chart;
    const minThreshold = chart.options.plugins.thresholdLine.minThreshold;
    if (y?.getPixelForValue) {
      const yPos = y?.getPixelForValue(minThreshold);
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(left, yPos);
      ctx.lineTo(right, yPos);
      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.setLineDash([5, 5]);
      ctx.stroke();
      ctx.restore();
    }
  },
};

ChartJS.register(thresholdLinePlugin);
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale
);

const soilParams = {
  Ex0T0: "Температура почвы",
  Ex1T0: "Температура почвы",
  Ex2T0: "Температура почвы",
  Ex3T0: "Температура почвы",
  Ex4T0: "Температура почвы",
  Ex5T0: "Температура почвы",
  Ex6T0: "Температура почвы",
  Ex7T0: "Температура почвы",
  Ex0T0: "Температура почвы",
  Ex1T1: "Температура почвы",
  Ex2T1: "Температура почвы",
  Ex3T1: "Температура почвы",
  Ex4T1: "Температура почвы",
  Ex5T1: "Температура почвы",
  Ex6T1: "Температура почвы",
  Ex7T1: "Температура почвы",
  Ex0T2: "Температура почвы",
  Ex1T2: "Температура почвы",
  Ex2T2: "Температура почвы",
  Ex3T2: "Температура почвы",
  Ex4T2: "Температура почвы",
  Ex5T2: "Температура почвы",
  Ex6T2: "Температура почвы",
  Ex7T2: "Температура почвы",
  Ex0T3: "Температура почвы",
  Ex1T3: "Температура почвы",
  Ex2T3: "Температура почвы",
  Ex3T3: "Температура почвы",
  Ex4T3: "Температура почвы",
  Ex5T3: "Температура почвы",
  Ex6T3: "Температура почвы",
  Ex7T3: "Температура почвы",
};

export default {
  name: "BarChart",
  components: {
    BarChartGenerator,
  },
  props: {
    rawData: {
      type: Array,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSensor: "",
      sensorOptions: [],
      chartData: {
        labels: [],
        datasets: [],
      },
      SETChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
          thresholdLine: {
            minThreshold: 5,
          },
        },
        hover: {
          mode: "index",
          intersect: false,
        },
        scales: {
          x: {},
          y: {
            min: null,
          },
        },
      },
      SETChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      minThreshold: 5,
      totalAboveThreshold: {},
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.widget.h * 14}px`,
        position: "relative",
      };
    },
    wrpStyles() {
      return {
        height: `${this.widget.h * 14}px`,
      };
    },
    soilSensors() {
      const params = this.rawData.length
        ? Object.keys(this.rawData[0].parameterValues)
        : [];

      let selectParams = [];

      Object.entries(soilParams).forEach(([key, value]) => {
        if (params.includes(key)) {
          selectParams.push({
            text: key,
            value: key,
          });
        }
      });
      return selectParams;
    },
  },
  watch: {
    rawData: {
      handler(newData) {
        this.generateChartData(newData);
      },
      immediate: true,
      deep: true,
    },
    minThreshold(newThreshold) {
      this.updateChartOptions(newThreshold);
      this.generateChartData(this.rawData);
    },
  },
  methods: {
    handleSensorChange(param) {
      if (param) {
        this.generateChartData(this.rawData, param);
      }
    },
    generateChartData(data, soilParam) {
      const aggregatedData = data.reduce((acc, item) => {
        const date = new Date(item.date).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = {};
        }
        const parameters = Object.keys(item.parameterValues);
        parameters.forEach((param) => {
          if (!acc[date][param]) {
            acc[date][param] = { sum: 0, count: 0 };
          }
          const value = item.parameterValues[param];
          if (value !== 0) {
            acc[date][param].sum += value;
            acc[date][param].count += 1;
          }
        });
        return acc;
      }, {});
      const labels = Object.keys(aggregatedData);
      const datasets = [];
      const colorPalette = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
      ];
      let colorIndex = 0;
      const total = {};

      const firstDate = Object.keys(aggregatedData)[0];
  
      const parameters = [
        {
          param: "t",
          label: this.$t('NEW_DASHBOARD.AIR_TEMPERATURE'),
          forDataLabel: "воздуха",
        },
      ];

      if (soilParam) {
        parameters.push({
          param: soilParam,
          label: this.$t('NEW_DASHBOARD.SOIL_TEMPERATURE') + " " + soilParam,
          forDataLabel: "почвы",
        });
      } else {
        for (const key of Object.keys(aggregatedData[firstDate])) {
          if (key.startsWith("Ex")) {
            parameters.push({
              param: key,
              label: this.$t('NEW_DASHBOARD.SOIL_TEMPERATURE')  + " " + key,
              forDataLabel: "почвы",
            });
            this.selectedSensor = key;
            break;
          }
        }
      }

      const SETDataSets = [];

      parameters.forEach((param) => {
        total[param.forDataLabel] = { sat: 0, set: 0 };

        const setParams = [];

        const paramData = labels.map((label) => {
          const dayData = aggregatedData[label][param.param];

          if (dayData.count === 0) {
            setParams.push(null);
            return null;
          }

          const avgValue = dayData.sum / dayData.count;
          if (avgValue >= this.minThreshold) {
            total[param.forDataLabel].sat += avgValue;
            setParams.push(
              total[param.forDataLabel].set + (avgValue - this.minThreshold)
            );
            total[param.forDataLabel].set += avgValue - this.minThreshold;
          } else {
            setParams.push(null);
          }
          return avgValue;
        });

        console.log(total)

        SETDataSets.push({
          label: param.label,
          data: setParams,
          borderColor: colorPalette[colorIndex % colorPalette.length],
          backgroundColor: colorPalette[colorIndex % colorPalette.length],
          fill: false,
        });

        datasets.push({
          label: param.label,
          data: paramData,
          borderColor: colorPalette[colorIndex % colorPalette.length],
          backgroundColor: colorPalette[colorIndex % colorPalette.length],
          fill: false,
        });

        colorIndex++;
      });

      this.SETChartData = { labels: [...labels], datasets: [...SETDataSets] };
      this.chartData = { labels: [...labels], datasets: [...datasets] };
      this.totalAboveThreshold = { ...total };

      const allValues = datasets
        .flatMap((dataset) => dataset.data)
        .filter((value) => value !== null);
      const minYValue = Math.min(...allValues);
      this.chartOptions.scales.y.min = minYValue < 0 ? minYValue : 0;
    },
    updateChartOptions(minThreshold) {
      this.chartOptions = {
        ...this.chartOptions,
        plugins: {
          ...this.chartOptions.plugins,
          thresholdLine: {
            minThreshold,
          },
        },
      };
      if (this.$refs.barChart && this.$refs.barChart.chartInstance) {
        this.$refs.barChart.chartInstance.options = this.chartOptions;
        this.$refs.barChart.chartInstance.update();
      }
    },
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  mounted() {
    this.generateChartData(this.rawData);
    if (this.$refs.barChart && this.$refs.barChart.chartInstance) {
      this.$refs.barChart.chartInstance.options.plugins.thresholdLine.minThreshold =
        this.minThreshold;
      this.$refs.barChart.chartInstance.update();
    }
  },
};
</script>

<style scoped>
.bar-chart-options-wrapper {
  display: flex;
  align-items: center;
}

.bar-chart-options {
  margin-left: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.input-field {
  display: block;
  width: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.5rem;
}

.bar-chart-options p {
  display: flex;
  font-size: 1.1rem;
  color: #333;
  margin-left: 0.5rem;
}

.bar-chart-options p strong {
  color: #007bff;
  margin-left: 10px;
}
</style>
