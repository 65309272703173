<template>
  <div>
    <table v-if="!loading">
      <thead>
        <tr>
          <th>Дата и время сервера</th>
          <th>Дата и время GPS</th>
          <th>Тип сеанса</th>
          <th>Датчик</th>
          <th>ЛЭП</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in paginatedData" :key="item.id" @click="selectItem(item)">
          <td>{{ formatDate(item.dateServer) }}</td>
          <td>{{ formatDate(item.dateUTC) }}</td>
          <td>{{ sessionType(item) }}</td>
          <td>{{ item.sensorId }}</td>
          <td>{{ widget.deviceName }}</td>
        </tr>
      </tbody>
    </table>
    <pagination :currentPage="currentPage" :totalPages="totalPages" @page-changed="changePage" />
    <div v-if="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from '../components/Pagination.vue'

export default {
  components: {
    Pagination
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    onSelect: {
      type: Function,
      required: true,
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      data: [],
      loading: false,
      currentPage: 1,
      totalCount: 0,
      itemsPerPage: 25,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.itemsPerPage);
    },
    paginatedData() {
      return this.data;
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        let parameters = {
          powerlineId: this.widget.deviceId,
          start,
          count: this.itemsPerPage,
          startDate: this.startDate,
          endDate: this.endDate
        }
        if(this.filters.sensorId.value) {
          parameters['sensorIds[]'] = this.filters.sensorId.value;
        }
        const resp = await axios.get(
          `api/oscillogram/measurements`, {
          params: parameters
        }
        );
        this.data = resp.data.items;
        this.totalCount = resp.data.totalCount;
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      } finally {
        this.loading = false;
      }
    },
    sessionType(el) {
      return el.flag != null ? 'Регистрация сигнала' : 'Плановый сеанс'
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchData();
      }
    },
    selectItem(item) {
      this.$emit("select", item.id);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    formatDateUnix(time) {
      const timestampInMilliseconds =
        time > 1e15 ? Math.floor(time / 1e6) : time;
      const date = new Date(time / 1000000);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
    },
  },
  watch: {
    startDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    endDate() {
      this.currentPage = 1;
      this.fetchData();
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ccc;
  text-align: left;
}

tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

h2 {
  margin-top: 20px;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.5rem;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.back-button:hover {
  color: #0056b3;
}

.pagination {
  margin-top: 20px;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-top: 20px;
}
</style>
