<template>
  <button
    @click="handleClick"
    :class="{ 'card-button': true, active: active }"
    class="card-button"
  >
    <img
      v-if="imageUrl"
      :src="imageUrl"
      alt="Button Image"
      class="card-button_img"
    />
    <p class="card-button_text">
      <slot>{{ text }}</slot>
    </p>
    <div class="remove-btn">
      <slot  name="delete-button"></slot> 
    </div>
  </button>
</template>

<script>
export default {
  name: "CardButton",
  props: {
    imageUrl: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.onClick();
    },
  },
};
</script>

<style scoped>
.card-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 217px;
  height: 217px;
  border-radius: 1rem;
  background: #ffff;
  margin-right: 1rem;
  padding: 0.75rem;
  justify-content: space-between;
  border: 2px solid #44486026;
  transition: 0.3s;
  cursor: pointer;
  position: relative; 
}
.card-button:hover,
.card-button.active {
  border-color: #64cff2;
}
.card-button_img {
  width: 70%;
  height: 70%;
  object-fit: cover;
}
.card-button_text {
  font-weight: 600;
  font-size: 13px;
  line-height: 15.5px;
}
.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}
</style>
