<template>
  <div>
    <div class="mb-3">
      <label for="name" class="form-label">{{ $t('NEW_DASHBOARD.DEVICE_NAME') }}</label>
      <input
        type="text"
        class="form-control"
        id="name"
        v-model="localForm.name"
        :class="{ 'is-invalid': errors.name }"
        @input="updateForm('name', localForm.name)"
      />
      <div class="invalid-feedback">
        {{ errors.name }}
      </div>
    </div>
    <div class="mb-3">
      <label for="phone" class="form-label">{{ $t('NEW_DASHBOARD.DEVICE_PHONE') }}</label>
      <input
        type="text"
        class="form-control"
        id="phone"
        v-model="localForm.phone"
        :class="{ 'is-invalid': errors.phone }"
        @input="updateForm('phone', localForm.phone)"
      />
      <div class="invalid-feedback">
        {{ errors.phone }}
      </div>
    </div>
    <div class="mb-3">
      <label for="imei" class="form-label">{{ $t('NEW_DASHBOARD.IMEI') }}</label>
      <input
        type="text"
        class="form-control"
        id="imei"
        v-model="localForm.imei"
        :class="{ 'is-invalid': errors.imei }"
        @input="updateForm('imei', localForm.imei)"
      />
      <div class="invalid-feedback">
        {{ errors.imei }}
      </div>
    </div>
    <div class="mb-3">
      <label for="password" class="form-label">{{ $t('NEW_DASHBOARD.PASSWORD') }}</label>
      <input
        type="password"
        class="form-control"
        id="password"
        v-model="localForm.password"
        :class="{ 'is-invalid': errors.password }"
        @input="updateForm('password', localForm.password)"
      />
      <div class="invalid-feedback">
        {{ errors.password }}
      </div>
    </div>
    <div class="mb-3 form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="active"
        v-model="localForm.active"
        @change="updateForm('active', localForm.active)"
      />
      <label class="form-check-label" for="active">{{ $t('NEW_DASHBOARD.ACTIVE') }}</label>
    </div>
  </div>
</template>

<script>
import { API_REQUEST } from "../../../../core/services/store/api.module";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localForm: { ...this.form },
      getDeviceTypesRequest: {
        resource: "/api/device-type",
        requestType: "GET",
        requestParams: { withParams: true, withFields: true },
      },
      deviceTypes: [],
    };
  },
  watch: {
    form: {
      handler(newForm) {
        this.localForm = { ...newForm };
      },
      deep: true,
    },
  },
  mounted() {
    this.loadDeviceTypes();
  },
  methods: {
    loadDeviceTypes: function () {
      return this.$store
        .dispatch(API_REQUEST, this.getDeviceTypesRequest)
        .then((response) => {
          let resp = response.data;
          this.deviceTypes = resp.map((type) => ({
            value: type.id,
            text: type.name,
          }));
        })
        .catch(this.onError);
    },
    updateForm(field, value) {
      this.$emit("update:form", { ...this.localForm, [field]: value });
    },
    onError(error) {
      console.error("Error loading device types:", error);
    },
  },
};
</script>

<style scoped></style>
