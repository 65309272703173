export const deviceTypes = {
  "c44de8bd-9283-429c-bea2-7f6ef9a5d3bc": {
    id: "c44de8bd-9283-429c-bea2-7f6ef9a5d3bc",
    name: "Sokol GA",
    trustLevelId: "26984710-8b2b-4b01-beba-5bd572013760",
    icon: "media/svg/buttons/gazanalizer.png",
  },
  "b02eae33-6d98-41ec-a9d2-63905277a357": {
    id: "b02eae33-6d98-41ec-a9d2-63905277a357",
    name: "Sokol-M",
    trustLevelId: "26984710-8b2b-4b01-beba-5bd572013761",
    icon: "media/svg/buttons/sokol_m.svg",
  },
  // "297e9ce2-9134-4004-b67f-21c1683aaede": {
  //   id: "297e9ce2-9134-4004-b67f-21c1683aaede",
  //   name: "SCAT",
  //   trustLevelId: "26984710-8b2b-4b01-beba-5bd572013761",
  //   icon: "media/svg/buttons/scat.png",
  // },
  // "b02eae33-6d98-41ec-a9d2-63905277a358": {
  //   id: "b02eae33-6d98-41ec-a9d2-63905277a358",
  //   name: "SKN",
  //   trustLevelId: "26984710-8b2b-4b01-beba-5bd572013761",
  //   icon: "media/svg/buttons/sokol-ma.png",
  // },
};
