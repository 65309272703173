<template>
  <div class="tabs-container">
    <div
      v-if="currentTab > 1 && withBackArrow"
      class="back-arrow"
      @click="goBack"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
        />
      </svg>
    </div>
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tab"
      :class="{ active: currentTab === tab.value }"
      @click="setActiveTab(tab)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardTabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: Number,
      required: true,
    },
    setActiveTab: {
      type: Function,
      default: () => {},
    },
    goBack: {
      type: Function,
      default: () => {},
    },
    withBackArrow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isActive(tab) {
      return this.currentTab === tab;
    },
  },
};
</script>

<style scoped>
.tabs-container {
  display: flex;
  border-bottom: 2px solid #b5b5c27a;
}
.back-arrow {
  font-size: 16px;
  cursor: pointer;
  padding: 1.15rem 1rem;
  color: black;
  display: flex;
  align-items: center;
}
.tab {
  font-size: 12px;
  line-height: 14px;
  padding: 1.15rem 1rem;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  top: 1px;
}
.tab.active {
  border-bottom: 2px solid #64cff2;
  font-weight: 600;
}
</style>
