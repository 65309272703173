<template>
  <div>
    <div @click="showModal">
      <slot name="button"></slot>
    </div>

    <b-modal
      :id="modalId"
      :title="$t('NEW_DASHBOARD.DELETION_QUESTION')"
      v-model="isModalVisible"
    >
      <div>
        <p>{{$t('NEW_DASHBOARD.DELETION_QUESTION')}}</p>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="confirmDelete" variant="danger">
          {{ $t('NEW_DASHBOARD.DELETE') }}
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="cancel()">
          {{ $t('NEW_DASHBOARD.CANCEL') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    modalId() {
      return `modal-delete-${this.itemId}`;
    },
  },
  methods: {
    showModal(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isModalVisible = true;
    },
    confirmDelete() {
      this.onDelete(this.itemId);
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.remove-btn {
  border-radius: 5px;
  background-color: rgba(228, 230, 239, 0.4);
}

.remove-btn:hover {
  background-color: rgba(206, 212, 241, 0.404);
}
</style>
