<template>
  <div>
    <slot name="open-modal-button" :openModal="openModal"></slot>
    <b-modal
      id="modal-1"
      ref="modal"
      size="xl"
      :title=" $t('NEW_DASHBOARD.NEW_POWERLINE')"
      hide-footer
    >
      <AddPowerLineForm  @submit="handleSubmit" @cancel="closeModal" />
    </b-modal>
  </div>
</template>

<script>
import AddPowerLineForm from "./AddPowerLineForm";

export default {
  name: "AddScatDeviceModal",
  components: {
    AddPowerLineForm,
  },
  methods: {
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
    handleSubmit(data) {
      this.$emit("addPowerLine", data);
      this.closeModal(); 
    },
  },
};
</script>
