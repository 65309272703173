import { render, staticRenderFns } from "./AccidentSynchronousOscillograms.vue?vue&type=template&id=2d4dcdf9&scoped=true"
import script from "./AccidentSynchronousOscillograms.vue?vue&type=script&lang=js"
export * from "./AccidentSynchronousOscillograms.vue?vue&type=script&lang=js"
import style0 from "./AccidentSynchronousOscillograms.vue?vue&type=style&index=0&id=2d4dcdf9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d4dcdf9",
  null
  
)

export default component.exports