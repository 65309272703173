<template>
  <div>
    <slot name="open-modal-button" :openModal="openModal"></slot>
    <b-modal
      id="modal-1"
      ref="modal"
      size="xl"
      :title="$t('NEW_DASHBOARD.ADD_TEMPLATE')"
      @show="loadDevice"
      hide-footer
    >
      <dashboard-tabs
        :tabs="steps"
        :currentTab="currentStep"
        :withBackArrow="true"
        :goBack="goBack"
      />
      <div v-if="currentStep === 1" class="empty_dashboard_view_buttons">
        <button class="dashboard_card_button" @click="selectChart('Linear')">
          <img
            class="dashboard_card_button_img"
            src="@/assets/media/charts/lines.svg"
            alt="Линейный график"
          />
          <p class="dashboard_card_button_text">
            {{ $t("NEW_DASHBOARD.CHART_LINEAR") }}
          </p>
        </button>
        <button class="dashboard_card_button" @click="selectChart('Table')">
          <img
            class="dashboard_card_button_img"
            src="@/assets/media/charts/grid.svg"
            alt="Таблица"
          />
          <p class="dashboard_card_button_text">
            {{ $t("NEW_DASHBOARD.CHART_TABLE") }}
          </p>
        </button>
        <button v-if="device?.typeName === 'Sokol GA'" class="dashboard_card_button" @click="selectChart('GasLinear')">
          <img
            class="dashboard_card_button_img"
            src="@/assets/media/charts/bubble.svg"
            alt="График показаний ГА"
          />
          <p class="dashboard_card_button_text">
            {{ $t("NEW_DASHBOARD.CHART_GA") }}
          </p>
        </button>
      </div>
      <div v-if="currentStep === 2">
        <form @submit.prevent="handleNext" novalidate>
          <div class="mb-3">
            <label for="name" class="form-label">{{
              $t("NEW_DASHBOARD.NAME")
            }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="form.name"
              :class="{ 'is-invalid': errors.name }"
              @input="updateForm('name', form.name)"
            />
            <div class="invalid-feedback">
              {{ errors.name }}
            </div>
          </div>
          <div class="mb-3">
            <label for="type" class="form-label">{{
              $t("NEW_DASHBOARD.INTERVAL")
            }}</label>
            <b-form-select
              v-model="form.interval"
              :options="intervalTypes"
              value-field="value"
              text-field="text"
              :class="{ 'is-invalid': errors.interval }"
              @change="updateForm('interval', form.interval)"
            >
              <template v-slot:first>
                <option :value="null" disabled>
                  {{ $t("NEW_DASHBOARD.SELECT_INTERVAL") }}
                </option>
              </template>
            </b-form-select>
            <div class="invalid-feedback">
              {{ errors.interval }}
            </div>
          </div>
          <div class="mb-3">
            <label for="groupType" class="form-label">{{
              $t("NEW_DASHBOARD.GROUP_TYPE")
            }}</label>
            <b-form-select
              v-model="form.groupType"
              :options="groupTypes"
              value-field="value"
              text-field="text"
              :class="{ 'is-invalid': errors.groupType }"
              @change="updateForm('groupType', form.groupType)"
            >
              <template v-slot:first>
                <option :value="null" disabled>
                  {{ $t("NEW_DASHBOARD.SELECT_GROUP_TYPE") }}
                </option>
              </template>
            </b-form-select>
            <div class="invalid-feedback">
              {{ errors.interval }}
            </div>
          </div>
          <div class="mb-3 d-flex align-items-center">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="autoUpdate"
                v-model="form.autoUpdate"
                @input="updateForm('autoUpdate', form.autoUpdate)"
              />
              <label for="autoUpdate" class="form-check-label ms-2">
                {{ $t("NEW_DASHBOARD.AUTO_UPDATE") }}
              </label>
            </div>
          </div>
        </form>
        <button @click="handleNext" class="button">
          {{ $t("NEW_DASHBOARD.NEXT") }}
        </button>
      </div>
      <div v-if="currentStep === 3">
        <div class="empty_dashboard_view_buttons">
          <button
            v-for="param in device.parameterMappings"
            :key="param.parameterId"
            class="dashboard_card_button param-card"
            @click="toggleParameter(param.code, param.parameterName)"
            :class="{
              'card-selected': param.code in parameters,
              'card-deselected': !(param.code in parameters),
            }"
          >
            <p class="dashboard_card_button_text">
              {{ param.parameterName }}
            </p>
          </button>
        </div>
        <button @click="selectAllParameters" class="mirror-button">
          {{ $t("NEW_DASHBOARD.SELECT_ALL_PARAMETERS") }}
        </button>
        <button @click="submitAddTemplate" class="button">
          {{ $t("NEW_DASHBOARD.NEW_TEMPLATE") }}
        </button>
        <div v-if="errors.parameters" class="invalid-feedback d-block">
          {{ errors.parameters }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DashboardTabs from "./DashboardTabs";
import axios from "axios";

export default {
  name: "AddDeviceModal",
  components: {
    DashboardTabs,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1,
      steps: [
        { label: this.$t("NEW_DASHBOARD.CHART_SELECTION"), value: 1 },
        { label: this.$t("NEW_DASHBOARD.DATA_INPUT"), value: 2 },
        { label: this.$t("NEW_DASHBOARD.PARAMETER_SELECTION"), value: 3 },
      ],
      form: {
        // name: `${this.device ? this.device.deviceName : 'Unnamed Device'} - ${this.device ? this.device.type : 'Unknown Type'}`,
        name: "",
        interval: "DAY",
        groupType: "NONE",
        autoUpdate: false,
      },
      errors: {},
      device: null,
      type: null,
      parameters: {},
      intervalTypes: [
        { value: "DAY", text: this.$t("NEW_DASHBOARD.DAY") },
        { value: "WEEK", text: this.$t("NEW_DASHBOARD.WEEK") },
        { value: "MONTH", text: this.$t("NEW_DASHBOARD.MONTH") },
        { value: "YEAR", text: this.$t("NEW_DASHBOARD.YEAR") },
      ],
      groupTypes: [
        { value: "NONE", text: this.$t("NEW_DASHBOARD.NO_GROUPING") },
        { value: "AUTO", text: this.$t("NEW_DASHBOARD.AUTO") },
        { value: "MINUTE", text: this.$t("NEW_DASHBOARD.BY_MINUTES") },
        { value: "HOUR", text: this.$t("NEW_DASHBOARD.BY_HOURS") },
        { value: "DAY", text: this.$t("NEW_DASHBOARD.BY_DAYS") },
        { value: "WEEK", text: this.$t("NEW_DASHBOARD.BY_WEEKS") },
        // { value: 'MONTH', text: 'По месяцам' },
        // { value: 'YEAR', text: 'По годам' }
      ],
    };
  },
  computed: {
    isParameterSelected() {
      return Object.keys(this.parameters).length > 0;
    },
  },
  methods: {
    loadDevice() {
      axios
        .get(`/api/device/${this.deviceId}?enrich=true`)
        .then((response) => {
          this.device = response.data;
        })
        .catch(this.onError);
    },
    onError(error) {
      console.error(this.$t('NEW_DASHBOARD.ERROR_LOADING_DEVICE'), error);
    },
    goBack() {
      this.currentStep = this.currentStep - 1;
    },
    setActiveAddTemplateStep(step) {
      this.currentStep = step.value;
    },
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
    submitAddTemplate() {
      if (!this.isParameterSelected) {
        this.$set(this.errors, "parameters", this.$t('NEW_DASHBOARD.PARAMETERS_REQUIRED'));
        return;
      }

      const templateData = {
        name: this.form.name,
        parameters: this.parameters,
        interval: this.form.interval,
        groupTypeName: this.form.groupType,
        autoUpdate: this.form.autoUpdate,
        type: this.type,
        deviceId: this.deviceId,
      };
      this.$emit("addTemplate", templateData);
      this.closeModal();
      this.resetForm();
    },
    selectChart(chart) {
      this.type = chart;
      this.setActiveAddTemplateStep({ value: 2 });
    },
    toggleParameter(code, name) {
      const newParameters = { ...this.parameters };
      if (newParameters.hasOwnProperty(code)) {
        delete newParameters[code];
      } else {
        newParameters[code] = name;
      }
      this.parameters = newParameters;
    },
    selectAllParameters() {
      const newParameters = {};
      if (this.device && this.device.parameterMappings) {
        this.device.parameterMappings.forEach((param) => {
          newParameters[param.code] = param.parameterName;
        });
        this.parameters = newParameters;
      }
    },
    updateForm(field, value) {
      this.form[field] = value;
    },
    validate() {
      this.errors = {};

      if (!this.form.name) {
        this.errors.name = this.$t('NEW_DASHBOARD.NAME_REQUIRED');
      }

      if (!this.form.interval) {
        this.errors.interval = this.$t('NEW_DASHBOARD.INTERVAL_REQUIRED');
      }

      if (!this.form.groupType) {
        this.errors.groupType = this.$t('NEW_DASHBOARD.GROUP_TYPE_REQUIRED');
      }
      return Object.keys(this.errors).length === 0;
    },
    handleNext() {
      if (this.currentStep === 2) {
        if (this.validate()) {
          this.setActiveAddTemplateStep({ value: 3 });
        }
      } else {
        this.setActiveAddTemplateStep({ value: this.currentStep + 1 });
      }
    },
    resetForm() {
      this.currentStep = 1;
      this.form = {
        name: "",
        interval: "",
      };
      this.errors = {};
      this.parameters = {};
    },
  },
};
</script>

<style scoped>
.empty_dashboard_view_buttons {
  padding: 1.15rem;
  display: flex;
  flex-wrap: wrap;
}
.dashboard_card_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  width: 217px;
  height: 217px;
  border-radius: 1rem;
  background: #ffffff;
  margin-right: 1rem;
  padding: 0.75rem;
  justify-content: space-between;
  border: 2px solid #44486026;
  transition: 0.3s;
  margin-bottom: 1rem;
  cursor: pointer;
}
.dashboard_card_button:hover {
  border-color: #64cff2;
}
.dashboard_card_button_img {
  width: 70%;
  height: 70%;
  flex: 1;
  padding: auto;
}
.card-selected {
  opacity: 1;
  border-color: #64cff2;
}
.card-deselected {
  opacity: 0.3;
}

.param-card {
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.button {
  background-color: #0abbf7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.2s;
  opacity: 0.8;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  margin-top: 1rem;
}
.button:hover {
  background-color: #fff;
  border-color: #64cff2;
  color: #0abbf7;
}

.mirror-button {
  background-color: #fff;
  color: #0abbf7;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.2s;
  opacity: 0.8;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid #64cff2;
  margin-top: 1rem;
  margin-right: 1rem;
}
.mirror-button:hover {
  background-color: #0abbf7;
  border-color: #64cff2;
  color: #fff;
}

.invalid-feedback {
  color: red;
  margin-top: 5px;
}
</style>
