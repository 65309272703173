<template>
    <div class="position-relative d-block">
    <div class="d-block text-white bg-dark position-absolute p-2 m-4 border rounded" 
        :style="tooltipStyle"
        :key="tooltipVisible"
        v-if="tooltipVisible && highlightedPoints.length">
        <div ref="tooltip" v-if="highlightedPoints.length">
            <div class="tooltip-title">
                <slot name="title">
                </slot>
            </div>
            <div v-for="(label, index) in dynamicLabels" class="tooltip-item"
                :key="label">
                <div v-if="visibleSeries[index]">
                    <b-icon
                        icon="square" 
                        :style="`background-color: ${seriesColors[index]}`" />
                    <strong> {{ label }}:</strong> {{ formatValue(index, label) }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "GraphTooltip",
    props: {
        tooltipVisible: {
            type: Boolean,
            default: false
        },
        highlightedPoints: Array,
        dynamicLabels: Array,
        seriesColors: Array,
        visibleSeries: Array,
        scalingFactors: Array,
        target: String,
        precision: Number
    },
    data() {
        return {
            tooltipPosition: [0,0],
            above: false
        }
    },
    mounted() {
        const targetRef = this.$parent.$refs[this.target];
        targetRef.addEventListener("mousemove", this.mouseMove);
    },
    beforeDestroy() {
        const targetRef = this.$parent.$refs[this.target];
        targetRef.removeEventListener("mousemove", this.mouseMove);
    },
    computed: {
        tooltipStyle() {
            return {
                top: `${this.tooltipPosition[1]}px`,
                left: `${this.tooltipPosition[0]}px`,
                'pointer-events': 'none',
                "z-index": '10',
                "width": "240px",
                "block-size": "fit-content"
            }
        },
    },
    methods: {
        mouseMove(event) {
            const my = event.offsetY;
            const tooltipX = this.highlightedPoints[0].canvasx || 0
            let tooltipY = 0;
            let maxDy = Number.MAX_VALUE;
            for(const point of this.highlightedPoints) {
                if(point.canvasy == NaN) continue;
                const diff = Math.abs(my-point.canvasy);
                if(diff < maxDy) {
                    maxDy = diff;
                    tooltipY = point.canvasy;
                }
            }
            this.tooltipPosition = [tooltipX,tooltipY];
        },
        findPoint(label) {
            return this.highlightedPoints.find((pt) => pt.name === label) || "-";
        },

        formatValue(index, label) {
            let minValue = 0;
            let scale = 1;
            if(this.scalingFactors[index]) {
                minValue = this.scalingFactors[index].minValue;
                scale = this.scalingFactors[index].scale;
            }
            const point = this.findPoint(label);
            const precision = this.precision ? this.precision : 2
            if (point) {
                const originalValue = point.yval * scale + minValue;
                return originalValue.toFixed(precision);
            }
        },
    }
}
</script>