<template>
  <div>
    <slot name="open-modal-button" :openModal="openModal"></slot>
    <b-modal
      id="modal-1"
      ref="modal"
      size="xl"
      title="Добавление виджета"
      @show="loadDevices"
      hide-footer
    >
      <dashboard-tabs
        :tabs="steps"
        :currentTab="currentStep"
        :withBackArrow="true"
        :goBack="goBack"
      />
      <div v-if="currentStep === 1" class="empty_dashboard_view_buttons">
        <button
          v-for="device in devices"
          :key="device.id"
          class="dashboard_card_button param-card"
          @click="toggleDevice(device.id, device.name)"
          :class="{
            'card-selected': device.id in selectedDevices,
            'card-deselected': !(device.id in selectedDevices),
          }"
        >
          <p class="dashboard_card_button_text">{{ device.name }}</p>
        </button>
        <button @click="handleNext" class="button">Далее</button>
      </div>
      <div v-if="currentStep === 2" class="empty_dashboard_view_buttons">
        <button class="dashboard_card_button" @click="selectChart('Linear')">
          <img
            class="dashboard_card_button_img"
            src="@/assets/media/charts/lines.svg"
            alt=""
          />
          <p class="dashboard_card_button_text">Линейный график</p>
        </button>
        <button class="dashboard_card_button" @click="selectChart('Table')">
          <img
            class="dashboard_card_button_img"
            src="@/assets/media/charts/grid.svg"
            alt=""
          />
          <p class="dashboard_card_button_text">Таблица</p>
        </button>
      </div>
      <div v-if="currentStep === 3">
        <form @submit.prevent="handleNext" novalidate>
          <div class="mb-3">
            <label for="name" class="form-label">Название</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="form.name"
              :class="{ 'is-invalid': errors.name }"
              @input="updateForm('name', form.name)"
            />
            <div class="invalid-feedback">
              {{ errors.name }}
            </div>
          </div>
          <div class="mb-3">
            <label for="type" class="form-label">Интервал</label>
            <b-form-select
              v-model="form.interval"
              :options="intervalTypes"
              :class="{ 'is-invalid': errors.interval }"
              @change="updateForm('interval', form.interval)"
            >
              <template v-slot:first>
                <option :value="null" disabled>Выберите интервал</option>
              </template>
            </b-form-select>
            <div class="invalid-feedback">
              {{ errors.interval }}
            </div>
          </div>
        </form>
        <button @click="handleNext" class="button">Далее</button>
      </div>
      <div v-if="currentStep === 4">
        <div
          v-for="device in devices.filter(
            (device) => !!selectedDevices[device.id]
          )"
          :key="device.id"
        >
          <p>{{ device.name }}</p>
          <div class="empty_dashboard_view_buttons">
            <button
              v-for="param in device.parameterMappings"
              :key="param.parameterId"
              class="dashboard_card_button param-card"
              @click="toggleParameter(param)"
              :class="{
                'card-selected': param.parameterId in parameters,
                'card-deselected': !(param.parameterId in parameters),
              }"
            >
              <p class="dashboard_card_button_text">
                {{ param.parameterName }}
              </p>
            </button>
          </div>
        </div>
        <button @click="submitAddTemplate" class="button">
          Добавить виджет
        </button>
        <button
          v-if="Object.keys(selectedDevices).length === 1"
          @click="submitAddTemplate"
          class="button"
        >
          Добавить виджет и сохранить как шаблон
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DashboardTabs from "./DashboardTabs";
import axios from "axios";

export default {
  name: "AddDeviceModal",
  components: {
    DashboardTabs,
  },
  props: {
    devices: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      currentStep: 1,
      steps: [
        { label: "1. Выбор устройств", value: 1 },
        { label: "2. Выбор графика", value: 2 },
        { label: "3. Ввод данных", value: 3 },
        { label: "4. Выбор параметров", value: 4 },
      ],
      form: {
        name: "",
        interval: "",
      },
      errors: {},
      device: {
        parameterMappings: [],
      },
      parameters: {},
      selectedDevices: {},
      loadParameters: false,
      intervalTypes: ["DAY", "WEEK", "MONTH", "YEAR"],
    };
  },
  methods: {
    loadDevices() {
      const promises = this.devices.map((device) => {
        return axios.get(`/api/device/${device.id}?enrich=true`);
      });
      Promise.all(promises).then((responses) => {
        responses.forEach((response, index) => {
          this.devices[index].parameterMappings =
            response.data.parameterMappings;
        });
      });
      // axios
      //   .get(`/api/device/byIds?enrich=true`, { params: { ids: this.devices.map((device) => device.id) }})
      //   .then((response) => {
      //     this.device = response.data;
      //   })
      //   .catch(this.onError);
    },
    onError(error) {
      console.error("Ошибка при загрузке устройства:", error);
    },
    goBack() {
      this.currentStep = this.currentStep - 1;
    },
    setActiveAddTemplateStep(step) {
      this.currentStep = step.value;
    },
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
    submitAddTemplate() {
      let widgetData = {
        name: this.form.name,
        parameters: this.parameters,
        interval: this.form.interval,
        type: this.type,
        isNotTemplate: true,
      };
      if (Object.keys(this.selectedDevices).length === 1) {
        widgetData.deviceId = this.devices[0].id;
      } else {
        widgetData.deviceIds = Object.keys(this.selectedDevices);
      }
      this.$emit("addWidget", widgetData);
      this.closeModal();
      this.resetForm();
    },
    selectChart(chart) {
      this.type = chart;
      this.setActiveAddTemplateStep({ value: 3 });
    },
    toggleParameter(param) {
      const newParameters = { ...this.parameters };
      if (Object.keys(this.selectedDevices).length === 1) {
        if (newParameters.hasOwnProperty(param.code)) {
          delete newParameters[param.code];
        } else {
          newParameters[param.code] = param.name;
        }
      } else {
        if (newParameters.hasOwnProperty(param.parameterId)) {
          delete newParameters[param.parameterId];
        } else {
          newParameters[param.parameterId] = param.parameterId;
        }
      }
      this.parameters = newParameters;
    },
    toggleDevice(id, name) {
      const newDevices = { ...this.selectedDevices };
      if (newDevices.hasOwnProperty(id)) {
        delete newDevices[id];
      } else {
        newDevices[id] = name;
      }
      this.selectedDevices = newDevices;
    },
    updateForm(field, value) {
      this.form[field] = value;
    },
    validate() {
      this.errors = {};

      if (!this.form.name) {
        this.errors.name = "Имя обязательно.";
      }

      if (!this.form.interval) {
        this.errors.interval = "Интервал обязателен.";
      }

      return Object.keys(this.errors).length === 0;
    },
    handleNext() {
      if (this.currentStep === 2) {
        if (this.validate()) {
          this.setActiveAddTemplateStep({ value: 3 });
        }
      } else {
        this.setActiveAddTemplateStep({ value: this.currentStep + 1 });
      }
    },
    resetForm() {
      this.currentStep = 1;
      this.form = {
        name: "",
        interval: "",
      };
      this.errors = {};
      this.parameters = {};
    },
  },
};
</script>

<style scoped>
.empty_dashboard_view_buttons {
  padding: 1.15rem;
  display: flex;
  flex-wrap: wrap;
}
.dashboard_card_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  width: 217px;
  height: 217px;
  border-radius: 1rem;
  background: #ffffff;
  margin-right: 1rem;
  padding: 0.75rem;
  justify-content: space-between;
  border: 2px solid #44486026;
  transition: 0.3s;
  margin-bottom: 1rem;
}
.dashboard_card_button:hover {
  border-color: #64cff2;
}
.dashboard_card_button_img {
  width: 70%;
  height: 70%;
  flex: 1;
  padding: auto;
}
.card-selected {
  opacity: 1;
  border-color: #64cff2;
}
.card-deselected {
  opacity: 0.3;
}

.param-card {
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.button {
  background-color: #0abbf7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.2s;
  opacity: 0.8;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.button:hover {
  background-color: #fff;
  border-color: #64cff2;
  color: #0abbf7;
}
</style>
