<template>
  <div>
    <table v-if="!selectedItem && !loading">
      <thead>
        <tr>
          <th>№</th>
          <th>Дата</th>
          <th>ЛЭП</th>
          <th>Место</th>
          <th>1Отпр.</th>
          <th>2Отпр.</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item) in paginatedData"
          :key="item.id"
          @click="fetchDetail(item)"
        >
          <td>{{ item.id }}</td>
          <td>{{ formatDate(item.dateUTC) }}</td>
          <td>{{ item.powerlineName }}</td>
          <td>{{ formatDistanceString(item) || 'N/A' }}</td>
          <td>{{ item.sendEvent ? 'Да' : 'Нет' }}</td>
          <td>{{ item.send ? 'Да' : 'Нет' }}</td>
        </tr>
      </tbody>
    </table>

    <pagination
      v-if="!selectedItem && totalPages > 1"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-changed="changePage"
    />

    <div v-if="selectedItem && !loading" class="detail">
      <button class="back-button" @click="backToTable">
        <i class="bi bi-arrow-left"></i> Назад
      </button>
      <OscillogramsChart :widget="widget" :data="selectedItem" />
    </div>

    <div v-if="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import OscillogramsChart from './OscillogramsChart';
import Pagination from '../components/Pagination.vue';

export default {
  components: {
    OscillogramsChart,
    Pagination, 
  },
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: [],
      selectedItem: null,
      loading: false,
      currentPage: 1,
      totalCount: 0,
      itemsPerPage: 25,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.itemsPerPage);
    },
    paginatedData() {
      return this.data;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        let parameters = {
            startDate: this.startDate,
            endDate: this.endDate,
            powerLineId: this.widget.deviceId,
            start,
            count: this.itemsPerPage,
        }
        if(this.filters.sensorId.value) {
          parameters['sensorIds[]'] = this.filters.sensorId.value;
        }
        const resp = await axios.get(`api/oscillogram/failures`, {
          params: parameters
        });
        this.data = resp.data.items;
        this.totalCount = resp.data.totalCount; 
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchDetail(item) {
      this.loading = true;
      try {
        const resp = await axios.get(`api/oscillogram/failures/${item.id}`);
        this.selectedItem = resp.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    backToTable() {
      this.selectedItem = null;
    },
    formatDistanceString(item) {
      const newItem = { ...item };
      const distance = newItem.sensorADistance;
      const sensorA = newItem.sensorAId;
      const sensorB = newItem.sensorBId || ' --';

      return `В ${distance} м от №${sensorA} к №${sensorB}`;
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    resetAndFetch() {
      this.currentPage = 1;
      this.fetchData();
    },
  },
  watch: {
    startDate: {
      handler: 'resetAndFetch',
      immediate: true
    },
    endDate: {
      handler: 'resetAndFetch',
      immediate: true
    }
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ccc;
  text-align: left;
}

tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.detail {
  position: relative;
  padding-top: 20px;
}

.back-button {
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 1rem;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
}

.back-button:hover {
  color: #0056b3;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-top: 20px;
}
</style>
