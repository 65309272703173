<template>
  <div class="container">
    <GraphTooltip :dynamicLabels="dynamicLabels" :highlightedPoints="highlightedPoints" :seriesColors="seriesColors"
      :tooltipVisible="tooltipVisible" :visibleSeries="visibleSeries" :scalingFactors="scalingFactors" :precision="4"
      target="mainGraph">
      <template v-slot:title>
        <strong>Date: </strong>
        {{ highlightedDate }}
      </template>
    </GraphTooltip>
    <div class="graph-and-tooltip">
      <div v-if="currentPdkLabel" class="position-relative d-block">
      <div class="p-2 mx-30 m-4 d-block position-absolute border rounded pdk-display">
        {{ $t("NEW_DASHBOARD.MPV") }} {{ currentPdkLabel ? currentPdkLabel : '—' }}:
        {{ currentPdkValue ? Number( currentPdkValue.toFixed(4)) : '—' }}
      </div>
    </div>
      <div ref="mainGraph" class="graph-container" :style="myStyles"></div>
    </div>
    <div v-if="dynamicLabels.length" class="legend-toggle m-2" :style="myStyles">
      <label v-for="(label, index) in dynamicLabels" :key="index" class="custom-checkbox">
        <input type="checkbox" :checked="visibleSeries[index]" @change="(e) => toggleSeriesVisibility(index, label, e)" />
        <span class="checkbox" :style="{ '--color': seriesColors[index] }"></span>
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
function getLang() {
  return localStorage.getItem("language") || "en";
}
import Dygraph from "dygraphs";
import GraphTooltip from "./GraphTooltip.vue";

export default {
  components: {
    GraphTooltip
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    pdks: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visibleSeries: [],
      seriesColors: [],
      dynamicLabels: [],
      scalingFactors: [],
      highlightedPoints: [],
      highlightedDate: null,
      tooltipVisible: true,
      initialRange: null,
      mainGraph: null,
      colorPalette: [
        '#3498db', '#e74c3c', '#2ecc71', '#9b59b6', '#34495e',
        '#1abc9c', '#2c3e50', '#f39c12', '#e67e22', '#16a085',
        '#c0392b', '#27ae60', '#8e44ad', '#2c3e50', '#f1c40f',
        '#95a5a6', '#2ecc71', '#3498db', '#e67e22', '#34495e',
        '#c0392b', '#27ae60', '#8e44ad', '#2c3e50', '#f1c40f',
      ],
      horizontalLines: [12],
      singleSeriesMode: true,
      currentPdkLabel: null,
      currentPdkValue: null,
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.widget.h * 26}px`,
      };
    },
  },
  mounted() {
    if (this.data && this.data.length > 0 && this.$refs.mainGraph) {
      this.initGraph();
      const initialIndex = this.visibleSeries.findIndex(
        (isVisible) => isVisible
      );
      if (initialIndex !== -1) {
        this.currentPdkLabel = this.dynamicLabels[initialIndex];

        this.currentPdkValue = this.pdks[this.currentPdkLabel];
      }
    }
  },
  methods: {
    initGraph() {
      if (
        !this.data ||
        this.data.length === 0 ||
        !this.data[0].parameterValues
      ) {
        console.warn("No data available for the graph");
        return;
      }

      this.dynamicLabels = Object.keys(this.data[0].parameterValues);

      this.seriesColors = this.dynamicLabels.map(
        (label, index) => this.colorPalette[index % this.colorPalette.length]
      );

      this.setInitialSeriesVisibility();

      const seriesData = this.prepareData();

      this.mainGraph = new Dygraph(this.$refs.mainGraph, seriesData, {
        labels: ["Date", ...this.dynamicLabels],
        strokeWidth: 1.5,
        showRangeSelector: true,
        visibility: this.visibleSeries,
        colors: this.seriesColors,
        legend: "never",
        highlightCallback: this.showCustomTooltip,
        unhighlightCallback: this.clearCustomTooltip,
        drawGrid: true,
        rightGap: 0,
        axes: {
          x: {
            pixelsPerLabel: 60,
            valueFormatter: function (ms) {
              const date = new Date(ms);
              return date.toLocaleDateString(getLang(), {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            },
            axisLabelFormatter: function (ms) {
              const date = new Date(ms);
              return date.toLocaleDateString(getLang(), {
                month: "short",
                day: "numeric",
              });
            },
          },
          y: { 
            drawAxis: true,
            drawGrid: true,
            axisLabelFormatter: function (ms) {
              return ms.toFixed(4);
            },
            axisLabelWidth: 80
          },
        },
        underlayCallback: (canvas, area, g) => {
          this.dynamicLabels.forEach((label, index) => {
            if (this.visibleSeries[index] && this.pdks[label]) {
              const pdkValue = this.pdks[label];
              const yPdk = g.toDomYCoord(pdkValue);
              const yGreen = g.toDomYCoord(pdkValue * 0.6);
              const yYellow = g.toDomYCoord(pdkValue);

              canvas.fillStyle = "rgba(0, 255, 0, 0.2)";
              canvas.fillRect(area.x, yGreen, area.w, area.y + area.h - yGreen);

              canvas.fillStyle = "rgba(255, 255, 0, 0.2)";
              canvas.fillRect(area.x, yYellow, area.w, yGreen - yYellow);

              canvas.fillStyle = "rgba(255, 0, 0, 0.2)";
              canvas.fillRect(area.x, area.y, area.w, yYellow - area.y);

              canvas.save();
              canvas.beginPath();
              canvas.setLineDash([5, 5]);
              canvas.moveTo(area.x, yPdk);
              canvas.lineTo(area.x + area.w, yPdk);
              canvas.strokeStyle = "blue";
              canvas.lineWidth = 1.5;
              canvas.stroke();
              canvas.restore();
            }
          });
        },
      });

      this.initialRange = this.mainGraph.xAxisRange();
      this.$refs.mainGraph.addEventListener("wheel", this.handleWheelZoom);
    },

    toggleSeriesVisibility(index, label, e) {
      e.target.checked = true

  if (this.visibleSeries[index]) {
    return;
  }

      if (this.singleSeriesMode) {
        this.visibleSeries = this.visibleSeries.map((_, i) => i === index);
      } else {
        this.$set(this.visibleSeries, index, !this.visibleSeries[index]);
      }

      if (this.mainGraph) {
        this.mainGraph.updateOptions({
          visibility: this.visibleSeries,
        });
      }

      if (this.visibleSeries[index]) {
        this.currentPdkLabel = label;
        this.currentPdkValue = this.pdks[label];
      } else {
        this.currentPdkLabel = null;
        this.currentPdkValue = null;
      }
    },
    calculateAveragePdkValue(index) {
      const label = this.dynamicLabels[index];
      const total = this.data.reduce(
        (acc, entry) => acc + entry.parameterValues[label],
        0
      );
      return total / this.data.length;
    },
    setInitialSeriesVisibility() {
      this.visibleSeries = this.dynamicLabels.map((label, index) => {
        if (index === 0) {
          return !(
            this.widget.disabledOptions &&
            this.widget.disabledOptions.includes(label)
          );
        }
        return false;
      });
    },

    prepareData() {
      const seriesData = [];

      this.data.forEach((entry) => {
        const date = new Date(entry.date);
        const values = this.dynamicLabels.map(
          (label) => entry.parameterValues[label]
        );
        seriesData.push([date, ...values]);
      });

      return seriesData;
    },

    formatDate(x) {
      return new Date(x).toLocaleString();
    },

    showCustomTooltip(event, x, points) {
      this.highlightedPoints = [...points];
      this.highlightedDate = this.formatDate(x);
      this.tooltipVisible = true;
    },

    clearCustomTooltip(event) {
      this.tooltipVisible = false;
    },

    handleWheelZoom(event) {
      event.preventDefault();

      const normal = event.deltaY ? event.deltaY / Math.abs(event.deltaY) : 0;
      const percentage = normal * -0.1;
      const axis = this.mainGraph.xAxisRange();
      const delta = (axis[1] - axis[0]) * percentage;
      let newStart = axis[0] + delta;
      let newEnd = axis[1] - delta;

      if (newStart < this.initialRange[0]) newStart = this.initialRange[0];
      if (newEnd > this.initialRange[1]) newEnd = this.initialRange[1];

      if (newEnd - newStart > 10) {
        this.mainGraph.updateOptions({
          dateWindow: [newStart, newEnd],
        });
      }
    },
  },
  beforeDestroy() {
    if (this.$refs.mainGraph) {
      this.$refs.mainGraph.removeEventListener("wheel", this.handleWheelZoom);
    }
    if (this.mainGraph) {
      this.mainGraph.destroy();
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  min-width: 100%;
  padding: 0;
  margin: 0;
}

.graph-and-tooltip {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  width: 100%;
}

.graph-container {
  flex-grow: 4;
  margin: 0;
  padding: 0;
}

.tooltip-item {
  margin: 4px 0;
  font-size: 12px;
  padding: 20px;
}

.pdk-display {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  width: 160px;
  max-width: 160px;
  z-index: 1001;
  pointer-events: none;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 14px;
}

.tooltip-item {
  margin: 4px 0;
  font-size: 12px;
}

.legend-toggle {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  overflow-x: auto;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--color);
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s;
  display: inline-block;
}

.custom-checkbox input:checked+.checkbox {
  background-color: var(--color);
}

.custom-checkbox input:not(:checked)+.checkbox {
  background-color: transparent;
}
</style>
