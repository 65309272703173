<template>
  <div @click="setVisibleHandler">
    <b-icon-eye
      v-if="isVisible"
      class="bi bi-eye"
      style="cursor: pointer"
    ></b-icon-eye>
    <b-icon-eye-slash
      v-else
      class="bi bi-eye"
      style="cursor: pointer"
    ></b-icon-eye-slash>
  </div>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    setVisible: {
      type: Function,
      required: true,
    },
  },
  methods: {
    setVisibleHandler() {
      this.setVisible(!this.isVisible);
    },
  },
};
</script>
