var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty_dashboard_view"},[_c('div',{staticClass:"empty_dashboard_view_settings"},[_c('dashboard-tabs',{attrs:{"tabs":_vm.steps,"currentTab":_vm.currentStep,"setActiveTab":() => {}}}),(_vm.currentStep === 1)?_c('div',{staticClass:"empty_dashboard_view_buttons"},[_c('CardButton',{attrs:{"active":_vm.enabledDevices === 'public',"imageUrl":"media/svg/buttons/public_devices_icon.svg","onClick":() => _vm.handleDevice('public'),"text":_vm.$t('NEW_DASHBOARD.PUBLIC_DEVICES')}}),_c('CardButton',{attrs:{"active":_vm.enabledDevices === 'my',"imageUrl":"media/svg/buttons/my_devices_icon.svg","onClick":() => _vm.handleDevice('my'),"text":_vm.$t('NEW_DASHBOARD.MY_DEVICES')}}),_c('CardButton',{attrs:{"active":_vm.enabledDevices === 'powerlines',"imageUrl":"media/svg/buttons/powerline_icon.svg","onClick":() => _vm.handleDevice('powerlines'),"text":_vm.$t('NEW_DASHBOARD.POWERLINE')}}),(_vm.powerLines.length > 0)?_c('CardButton',{attrs:{"active":_vm.enabledDevices === 'scat',"imageUrl":"media/svg/buttons/my_devices_icon.svg","onClick":() => _vm.handleDevice('scat'),"text":_vm.$t('NEW_DASHBOARD.SKAT_DEVICES')}}):_vm._e()],1):_vm._e(),(_vm.currentStep === 2)?_c('div',{staticClass:"empty_dashboard_view_buttons"},[_vm._l((_vm.filteredDevices),function(device){return _c('CardButton',{key:device.id,attrs:{"active":device.id === _vm.selectedDevice?.id,"imageUrl":_vm.deviceTypes[device.typeId]
            ? _vm.deviceTypes[device.typeId].icon
            : 'media/devices/sokol_tdv.svg',"onClick":() => _vm.handleSuggestedVariant(device),"text":device.name},scopedSlots:_vm._u([{key:"delete-button",fn:function(){return [(_vm.enabledDevices !== 'public')?_c('ConfirmDeleteModal',{attrs:{"item-id":device.id,"on-delete":() => {
                if (_vm.enabledDevices === 'powerlines') {
                  return _vm.$emit('removePowerLine', device.id);
                }
                return _vm.$emit('deleteDevice', device.id);
              }},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{staticClass:"remove-btn"},[_c('img',{attrs:{"src":"media/svg/buttons/delete-blue.svg","alt":"Delete"}})])]},proxy:true}],null,true)}):_vm._e()]},proxy:true}],null,true)})}),(_vm.enabledDevices === 'powerlines')?_c('AddPowerLineModal',{on:{"addPowerLine":function($event){return _vm.$emit('addPowerLine', $event)}},scopedSlots:_vm._u([{key:"open-modal-button",fn:function({ openModal }){return [_c('CardButton',{attrs:{"imageUrl":"media/svg/buttons/add_device_dashboard.svg","onClick":openModal,"text":_vm.$t('NEW_DASHBOARD.NEW_POWERLINE')}})]}}],null,false,899169740)}):_vm._e(),(_vm.enabledDevices === 'scat' && _vm.powerLines.length > 0)?_c('AddScatDeviceModal',{attrs:{"powerLines":_vm.powerLines},on:{"addScatDevice":function($event){return _vm.$emit('addScatDevice', $event)}},scopedSlots:_vm._u([{key:"open-modal-button",fn:function({ openModal }){return [_c('CardButton',{attrs:{"imageUrl":"media/svg/buttons/add_device_dashboard.svg","onClick":openModal,"text":_vm.$t('NEW_DASHBOARD.NEW_DEVICE')}})]}}],null,false,1823511909)}):_vm._e(),(_vm.enabledDevices === 'my')?_c('AddDeviceModal',{on:{"addDevice":function($event){return _vm.$emit('addDevice', $event)}},scopedSlots:_vm._u([{key:"open-modal-button",fn:function({ openModal }){return [_c('CardButton',{attrs:{"imageUrl":"media/svg/buttons/add_device_dashboard.svg","onClick":openModal,"text":_vm.$t('NEW_DASHBOARD.NEW_DEVICE')}})]}}],null,false,1823511909)}):_vm._e()],2):_vm._e(),(_vm.currentStep === 3)?_c('div',{staticClass:"empty_dashboard_view_buttons"},[_vm._l((_vm.selectedDevice.available_widgets),function(template){return _c('CardButton',{key:template.id,attrs:{"active":template.widget?.i === _vm.selectedWidget?.widget?.i,"imageUrl":_vm.widgetList?.[template.type].svg,"onClick":() =>
            _vm.addWidgetOnDashboard({
              widget: {
                ...template,
                isLep: _vm.enabledDevices === 'powerlines' ? true : false,
              },
              deviceId: _vm.selectedDevice?.id,
            }),"text":template.isCustom
            ? template.name
            : _vm.$t(_vm.widgetList?.[template.type].name)},scopedSlots:_vm._u([{key:"delete-button",fn:function(){return [(template.isCustom)?_c('ConfirmDeleteModal',{attrs:{"item-id":template.id,"on-delete":() => _vm.$emit('deleteTemplate', template.id)},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('button',{staticClass:"remove-btn"},[_c('img',{attrs:{"src":"media/svg/buttons/delete-blue.svg","alt":"Delete"}})])]},proxy:true}],null,true)}):_vm._e()]},proxy:true}],null,true)})}),(_vm.enabledDevices !== 'powerlines' && _vm.enabledDevices !== 'scat')?_c('AddingTemplate',{attrs:{"deviceId":_vm.selectedDevice?.id},on:{"addTemplate":function($event){return _vm.$emit('addTemplate', $event)}},scopedSlots:_vm._u([{key:"open-modal-button",fn:function({ openModal }){return [_c('CardButton',{attrs:{"imageUrl":"media/svg/buttons/add_device_dashboard.svg","onClick":openModal,"text":_vm.$t('NEW_DASHBOARD.NEW_TEMPLATE')}})]}}],null,false,3051632173)}):_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"empty_dashboard_view_footer"},[(_vm.currentStep > 1)?_c('button',{staticClass:"btn prev font-weight-bold text-uppercase px-9 py-4 mr-2",attrs:{"type":"button"},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('NEW_DASHBOARD.BACK'))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }