<template>
  <div class="table-container">
    <div class="table-controls">
      <input
        type="number"
        v-model.number="minThreshold"
        @input="generateTableData(rawData)"
        placeholder="Введите минимальный порог"
        class="input-field"
      />

      <b-form-select
        class="form-select"
        v-model="selectedSensor"
        :options="soilSensors"
        @change="handleSensorChange"
        style="height: 38px"
      >
      </b-form-select>
    </div>
    <div class="table-header" ref="tableHeader">
      <table :class="cssClasses">
        <thead>
          <tr>
            <th>{{$t('NEWS.DATE') }}</th>
            <th v-for="header in headers" :key="header">{{ header }}</th>
          </tr>
        </thead>
      </table>
    </div>
    <div
      class="table-body"
      ref="tableBody"
      :style="bodyStyles"
      @scroll="syncScroll"
    >
      <table :class="cssClasses">
        <tbody>
          <tr v-for="(row, rowIndex) in formattedRows" :key="rowIndex">
            <td>{{ row.date }}</td>
            <td v-for="(value, cellIndex) in row.values" :key="cellIndex">
              {{ parseFloat(value).toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="table-footer"
      ref="tableFooter"
      style="overflow-y: scroll"
      @scroll="syncScroll"
    >
      <table :class="cssClasses">
        <thead>
          <tr>
            <th>{{  $t('NEW_DASHBOARD.CALCULATIONS')  }}</th>
            <th v-for="header in headers" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>{{ $t('NEW_DASHBOARD.SAT_AMOUNT') }}</td>
            <td v-for="(satSum, index) in satSums" :key="index">
              {{ satSum.toFixed(2) }}
            </td>
          </tr>
          <tr>
            <td>{{ $t('NEW_DASHBOARD.SET_AMOUNT') }}</td>
            <td v-for="(setSum, index) in setSums" :key="index">
              {{ setSum.toFixed(2) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
const soilParams = {
  Ex0T0: "Температура почвы",
  Ex1T0: "Температура почвы",
  Ex2T0: "Температура почвы",
  Ex3T0: "Температура почвы",
  Ex4T0: "Температура почвы",
  Ex5T0: "Температура почвы",
  Ex6T0: "Температура почвы",
  Ex7T0: "Температура почвы",
  Ex0T0: "Температура почвы",
  Ex1T1: "Температура почвы",
  Ex2T1: "Температура почвы",
  Ex3T1: "Температура почвы",
  Ex4T1: "Температура почвы",
  Ex5T1: "Температура почвы",
  Ex6T1: "Температура почвы",
  Ex7T1: "Температура почвы",
  Ex0T2: "Температура почвы",
  Ex1T2: "Температура почвы",
  Ex2T2: "Температура почвы",
  Ex3T2: "Температура почвы",
  Ex4T2: "Температура почвы",
  Ex5T2: "Температура почвы",
  Ex6T2: "Температура почвы",
  Ex7T2: "Температура почвы",
  Ex0T3: "Температура почвы",
  Ex1T3: "Температура почвы",
  Ex2T3: "Температура почвы",
  Ex3T3: "Температура почвы",
  Ex4T3: "Температура почвы",
  Ex5T3: "Температура почвы",
  Ex6T3: "Температура почвы",
  Ex7T3: "Температура почвы",
};

export default {
  name: "TableComponent",
  props: {
    rawData: {
      type: Array,
      required: true,
    },
    cssClasses: {
      type: String,
      default: "",
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSensor: "",
      sensorOptions: [],
      data: {
        labels: [],
        datasets: [],
      },
      satSums: [],
      setSums: [],
      minThreshold: 5,
      selectedSoilParam: "",
    };
  },
  computed: {
    soilSensors() {
      const params = this.rawData.length
        ? Object.keys(this.rawData[0].parameterValues)
        : [];

      let selectParams = [];

      Object.entries(soilParams).forEach(([key, value]) => {
        if (params.includes(key)) {
          selectParams.push({
            text: key,
            value: key,
          });
        }
      });
      return selectParams;
    },
    headers() {
      return this.data.datasets.map((dataset) => dataset.label);
    },
    formattedRows() {
      return this.data.labels.map((label, index) => {
        return {
          date: label,
          values: this.data.datasets.map((dataset) =>
            dataset.data[index] !== undefined ? dataset.data[index] : "_"
          ),
        };
      });
    },
    bodyStyles() {
      return {
        height: `${this.widget.h * 11}px`,
        overflowY: "scroll",
        overflowX: "auto",
      };
    },
  },
  watch: {
    rawData: {
      handler(newData) {
        this.generateTableData(newData);
      },
      immediate: true,
      deep: true,
    },
    minThreshold(newThreshold) {
      this.generateTableData(this.rawData);
    },
    soilSensors() {
      const params = this.rawData.length
        ? Object.keys(this.rawData[0].parameterValues)
        : [];

      let selectParams = [];

      Object.entries(soilParams).forEach(([key, value]) => {
        if (params.includes(key)) {
          selectParams.push({
            text: key,
            value: key,
          });
        }
      });
      return selectParams;
    },
    selectedSoilParam() {
      this.generateTableData(this.rawData);
    },
  },
  methods: {
    handleSensorChange(param) {
      if (param) {
        this.generateTableData(this.rawData, param);
      }
    },
    generateTableData(data, soilParam) {
      const aggregatedData = data
      .reduce((acc, item) => {
        const date = new Date(item.date).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = {};
        }
        const parameters = Object.keys(item.parameterValues);
        parameters.forEach((param) => {
          if (!acc[date][param]) {
            acc[date][param] = { sum: 0, count: 0 };
          }
          const value = item.parameterValues[param] || 0;
          if (value !== 0) {
            acc[date][param].sum += value;
            acc[date][param].count += 1;
          }
        });
        return acc;
      }, {});

      const labels = Object.keys(aggregatedData);
      const datasets = [];
      const total = {};

      const firstDate = Object.keys(aggregatedData)[0];

      const parameters = [
        {
          param: "t",
          label: this.$t('NEW_DASHBOARD.AIR_TEMPERATURE'),
          forDataLabel: "воздуха",
        },
      ];

      if (soilParam) {
        parameters.push({
          param: soilParam,
          label: this.$t('NEW_DASHBOARD.SOIL_TEMPERATURE') + " " + soilParam,
          forDataLabel: "почвы",
        });
      } else {
        for (const key of Object.keys(aggregatedData[firstDate])) {
          if (key.startsWith("Ex")) {
            parameters.push({
              param: key,
              label: this.$t('NEW_DASHBOARD.SOIL_TEMPERATURE')  + " " + key,
              forDataLabel: "почвы",
            });
            this.selectedSensor = key;
            break;
          }
        }
      }

      // const parameters = this.selectedSoilParam
      //   ? [this.selectedSoilParam]
      //   : Object.keys(aggregatedData[firstDate]);

      parameters.forEach(({param, label}) => {
        total[param] = { sat: 0, set: 0 };
        const paramData = labels
        .map((label) => {
          const dayData = aggregatedData[label][param];
          if (dayData.count === 0) {
            return null;
          }
          const avgValue = dayData.sum / dayData.count;
          if (avgValue >= this.minThreshold) {
            total[param].sat += avgValue;
            total[param].set += avgValue - this.minThreshold;
          }
          return avgValue;
        });

        datasets.push({
          label: label.toUpperCase(),
          data: paramData,
        });
      });

      this.data = {
        labels,
        datasets,
      };
      this.satSums = Object.values(total).map((t) => t.sat);
      this.setSums = Object.values(total).map((t) => t.set);
    },
    syncScroll() {
      const tableHeader = this.$refs.tableHeader;
      const tableBody = this.$refs.tableBody;
      const tableFooter = this.$refs.tableFooter;
      tableHeader.scrollLeft = tableBody.scrollLeft;
      tableFooter.scrollLeft = tableBody.scrollLeft;
    },
  },
  mounted() {
    this.generateTableData(this.rawData, this.selectedSoilParam);
  },
};
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow: hidden;
}

.table-controls {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  width: 50%;
  column-gap: 1rem;
}

.table-header,
.table-footer {
  overflow: hidden;
}

.table-body {
  overflow-y: scroll;
  overflow-x: auto;
}

.table-container ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-container ::-webkit-scrollbar-thumb {
  background: #64cff2;
  border-radius: 10px;
}

.table-container ::-webkit-scrollbar-thumb:hover {
  background: #23bcf0;
}

.table-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  color: #02151b;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 150px;
  max-width: 150px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

.input-field {
  display: block;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.5rem;
}
</style>
