<template>
  <div class="chart-container">
    <Radar
      :chartOptions="chartOptions"
      :chartData="chartData"
      :style="myStyles"
      ref="polarAreaChart"
    />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "vue-chartjs";
const customAngleLinesPlugin = {
  id: "customAngleLinesPlugin",

  afterDraw(chart) {
    const { ctx, scales } = chart;
    const radialScale = scales.r;

    if (!radialScale || !chart.data.labels) {
      return;
    }

    const labels = chart.data.labels;
    const numberOfLabels = labels.length;

    // Проверяем углы и отображаем линии там, где углы кратны 45
    labels.forEach((label, index) => {
      // Вычисляем угол в градусах для текущей метки
      const angleDegrees = (index / numberOfLabels) * 360;

      if (angleDegrees % 45 === 0 && label) {
        ctx.save();
        ctx.strokeStyle = "#CCC"; // Цвет линии
        ctx.lineWidth = 1; // Ширина линии
        ctx.beginPath();
        ctx.moveTo(radialScale.xCenter, radialScale.yCenter);
        ctx.lineTo(
          radialScale.getPointPosition(index, radialScale.drawingArea).x,
          radialScale.getPointPosition(index, radialScale.drawingArea).y
        );
        ctx.stroke();
        ctx.restore();
      }
    });
  },
};

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  customAngleLinesPlugin
);

// Плагин для кастомизации angleLines

export default {
  name: "PolarAreaChart",
  components: {
    Radar,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.generateAngleLabels(),
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            beginAtZero: false,
            min: 0,
            ticks: {
              stepSize: 2,
            },
            grid: {
              display: true,
              borderDash: [5, 5],
            },
            angleLines: {
              display: false,
            },
          },
        },
        elements: {
          line: {
            display: false,
            tension: 0,
            borderWidth: 0,
            fill: false,
          },
          point: {
            radius: 3,
            hitRadius: 10,
            backgroundColor: "blue",
            borderWidth: 0,
          },
        },
        plugins: {
          customAngleLinesPlugin,
          legend: {
            position: "right",
          },

          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const index = tooltipItem.dataIndex;
                const date =
                  (tooltipItem.dataset.dates[index] &&
                    new Date(
                      tooltipItem.dataset.dates[index]
                    ).toLocaleDateString()) ||
                  "N/A";
                const direction =
                  tooltipItem.dataset.originalData[index] || "N/A";
                const speed = tooltipItem.dataset.data[index] || "N/A";
                return [
                  `Дата: ${date}`,
                  `Направление: ${direction}°`,
                  `Скорость: ${speed} м/с`,
                ];
              },
            },
          },
        },
      },
    };
  },
  computed: {
    myStyles() {
      return {
        height: `${this.widget.h * 26}px`,
        position: "relative",
      };
    },
  },
  watch: {
    data: {
      handler(newData) {
        this.createDynamicDatasets(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    generateAngleLabels() {
      const angleLabels = Array.from({ length: 360 }, (_, i) => {
        if (i % 45 === 0) {
          let direction;
          if (i === 0 || i === 360) {
            direction = "N"; 
          } else if (i === 45) {
            direction = "NE"; 
          } else if (i === 90) {
            direction = "E"; 
          } else if (i === 135) {
            direction = "SE"; 
          } else if (i === 180) {
            direction = "S"; 
          } else if (i === 225) {
            direction = "SW"; 
          } else if (i === 270) {
            direction = "W"; 
          } else if (i === 315) {
            direction = "NW"; 
          }
          return `${i}° (${direction})`;
        }
        return null;
      });
      return angleLabels;
    },
    createDynamicDatasets(data) {
      if (!data || data.length === 0) {
        console.error("Нет данных для отображения");
        return;
      }

      const angles = data.map((item) => item.parameterValues.WD);
      const windSpeedData = data.map((item) => item.parameterValues.WV || null);
      const dates = data.map((item) => item.date || "Нет данных");

      // Определяем диапазоны скоростей ветра
      const speedRanges = [
        { label: "0-2 м/с", min: 0, max: 2, color: "blue" },
        { label: "2-5 м/с", min: 2, max: 5, color: "green" },
        { label: "5-8 м/с", min: 5, max: 8, color: "orange" },
        { label: "8-11 м/с", min: 8, max: 11, color: "red" },
        { label: "11-14 м/с", min: 11, max: 14, color: "purple" },
        { label: "14-17 м/с", min: 14, max: 17, color: "brown" },
        { label: "17-20 м/с", min: 17, max: 20, color: "pink" },
        { label: "20+ м/с", min: 20, max: Infinity, color: "black" },
      ];

      // Инициализация datasets на основе диапазонов скоростей
      const datasets = speedRanges.map((range) => ({
        label: range.label,
        data: Array(360).fill(null),
        borderColor: range.color,
        backgroundColor: `${range.color}`,
        fill: false,
        dates: Array(360).fill("Нет данных"),
        originalData: Array(360).fill(null),
      }));

      angles.forEach((angle, index) => {
        const roundedAngle = Math.round(angle);
        const windSpeed = windSpeedData[index];
        const date = dates[index];

        speedRanges.forEach((range, rangeIndex) => {
          if (windSpeed >= range.min && windSpeed < range.max) {
            datasets[rangeIndex].data[roundedAngle] = windSpeed;
            datasets[rangeIndex].dates[roundedAngle] = date;
            datasets[rangeIndex].originalData[roundedAngle] = angle;
          }
        });
      });

      this.chartData.datasets = datasets.filter((dataset) =>
        dataset.data.some((value) => value !== null)
      );
    },
  },
  mounted() {
    this.createDynamicDatasets(this.data);
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
}
</style>
