<template>
  <div class="position-relative">
    <LineChart class="position-relative" :data="highFreqData" :widget="widget" :measure-id="measureId" :is-low-freq="false" />
    <LineChart class="position-relative" :data="lowFreqData" :widget="widget" :measure-id="measureId" :is-low-freq="true" />
  </div>
</template>
<script>
import LineChart from "./ScatIndicationsLineChart.vue";

export default {
  name: "OscillationChart",
  components: {
    LineChart,
  },
  props: {
    oscData: {
      type: Object,
      required: true,
    },
    widget: {
      type: Object,
      required: true,
    },
    measureId: {
      type: Number,
      required: false
    }
  },
  computed: {
    lowFreqData() {
      return {
        "CH-0 LowFreq (0-267)": this.oscData.osc_ch0_left_LowFreq
          ? this.oscData.osc_ch0_left_LowFreq
          : [],
        "CH-1 LowFreq (0-267)": this.oscData.osc_ch1_left_LowFreq
          ? this.oscData.osc_ch1_left_LowFreq
          : [],
        "CH-2 LowFreq (0-267)": this.oscData.osc_ch2_left_LowFreq
          ? this.oscData.osc_ch2_left_LowFreq
          : [],

        "CH-0 LowFreq (268-536)": this.oscData.osc_ch0_right_LowFreq
          ? this.oscData.osc_ch0_right_LowFreq
          : [],
        "CH-1 LowFreq (268-536)": this.oscData.osc_ch1_right_LowFreq
          ? this.oscData.osc_ch1_right_LowFreq
          : [],
        "CH-2 LowFreq (268-536)": this.oscData.osc_ch2_right_LowFreq
          ? this.oscData.osc_ch2_right_LowFreq
          : [],
      };
    },
    highFreqData() {
      return {
        "CH-0": this.oscData.osc_ch0 ? this.oscData.osc_ch0 : [],
        "CH-1": this.oscData.osc_ch1 ? this.oscData.osc_ch1 : [],
        "CH-2": this.oscData.osc_ch2 ? this.oscData.osc_ch2 : [],
      };
    },
  },
};
</script>
