<template>
  <div class="container mt-4">
    <form @submit.prevent="validateAndSubmit" class="needs-validation" novalidate>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="name" class="form-label">{{ $t('NEW_DASHBOARD.NAME') }}</label>
          <input
            type="text"
            id="name"
            v-model="name"
            :placeholder="$t('NEW_DASHBOARD.NAME_PLACEHOLDER')"
            :class="{ 'is-invalid': nameError }"
            class="form-control"
            maxlength="50"
          />
          <div v-if="nameError" class="invalid-feedback">{{ nameError }}</div>
        </div>

        <div class="col-md-6">
          <label for="epsilon" class="form-label">{{ $t('NEW_DASHBOARD.EPSILON') }}</label>
          <input
            type="number"
            id="epsilon"
            v-model="epsilon"
            :placeholder="$t('NEW_DASHBOARD.EPSILON_PLACEHOLDER')"
            :class="{ 'is-invalid': epsilonError }"
            class="form-control"
          />
          <div v-if="epsilonError" class="invalid-feedback">{{ epsilonError }}</div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-6">
          <button type="submit" class="btn btn-primary w-100">{{ $t('NEW_DASHBOARD.NEW_POWERLINE') }}</button>
        </div>
        <div class="col-md-6">
          <button type="button" class="btn btn-secondary w-100" @click="$emit('cancel')">{{ $t('NEW_DASHBOARD.CANCEL') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      epsilon: null,
      nameError: null,
      epsilonError: null,
    };
  },
  methods: {
    validateAndSubmit() {
      this.nameError = this.name ? null : this.$t('NEW_DASHBOARD.NAME_REQUIRED');
      this.epsilonError =
        this.epsilon && this.epsilon > 0 && this.epsilon < 10000
          ? null
          : this.$t('NEW_DASHBOARD.EPSILON_REQUIRED');

      if (!this.nameError && !this.epsilonError) {
        this.submitForm();
      }
    },
    submitForm() {
      this.$emit("submit", {
        name: this.name,
        epsilon: this.epsilon,
      });
    },
  },
};
</script>

<style scoped>
.form-label {
  font-weight: bold;
}

.form-select,
.form-control {
  font-size: 1rem;
}

.btn-primary,
.btn-secondary {
  font-size: 1rem;
}

.invalid-feedback {
  font-size: 0.875rem;
}

.row.g-3 > [class*="col-"] {
  padding-top: 0.5rem;
}
</style>
