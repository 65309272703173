export const widgetList = {
  scatDeviceWidgetList: {
    Linear: {
      isLep: false,
      type: 'Linear',
      name: 'NEW_DASHBOARD.TEMP_OSCILLOGRAMS', // "Временные осциллограммы изменения"
      svg: 'media/svg/widgets/ChartReadings.svg',
    },
  },
  devicesWidgetList: {
    Linear: {
      type: 'Linear',
      name: 'NEW_DASHBOARD.GRAPH_READINGS', // "График показаний"
      svg: 'media/svg/widgets/ChartReadings.svg',
    },
    Table: {
      type: 'Table',
      name: 'NEW_DASHBOARD.TABLE_READINGS', // "Таблица показаний"
      svg: 'media/svg/widgets/TableReadings.svg',
    },
    TemperatureTable: {
      type: 'TemperatureTable',
      name: 'NEW_DASHBOARD.TABLE_SAT_SET', // "Таблица САТ/СЭТ"
      svg: 'media/svg/widgets/TableSatSet.svg',
    },
    TemperatureChart: {
      type: 'TemperatureChart',
      name: 'NEW_DASHBOARD.GRAPH_SAT_SET', // "График САТ/СЭТ"
      svg: 'media/svg/widgets/ChartSatSet.svg',
    },
    WindChart: {
      type: 'WindChart',
      name: 'NEW_DASHBOARD.WIND_ROSE', // "Роза ветров"
      svg: 'media/svg/widgets/WindRose.svg',
    },
    GasLinear: {
      type: 'GazLinear',
      name: 'NEW_DASHBOARD.GAS_LINEAR',
      svg: 'media/svg/widgets/ChartReadings.svg',
    },
    
  },
  scatWidgetList: {
    ScatIndications: {
      isLep: true,
      type: 'ScatIndications',
      name: 'NEW_DASHBOARD.ALL_READINGS', // "Все показания"
      svg: 'media/svg/widgets/TableSatSet.svg',
    },
    ASO: {
      isLep: true,
      type: 'ASO',
      name: 'NEW_DASHBOARD.ASO', // "АСО"
      svg: 'media/svg/widgets/ChartReadings.svg',
    },
  },
 
};

export const  extractWidgets = () => {
  const result = {};

  Object.values(widgetList).forEach((widgetGroup) => {
    Object.entries(widgetGroup).forEach(([key, widget]) => {
      result[key] = widget; 
    });
  });

  return result;
};