<template>
  <div>
    <slot name="open-modal-button" :openModal="openModal"></slot>
    <b-modal
      id="modal-1"
      ref="modal"
      size="xl"
      :title="$t('NEW_DASHBOARD.NEW_DEVICE')"
      hide-footer
    >
      <dashboard-tabs
        :tabs="addDeviceSteps"
        :currentTab="currentAddDeviceStep"
        :setActiveTab="() => {}"
        :withBackArrow="true"
        :goBack="goBack"
      />
      <div
        v-if="currentAddDeviceStep === 1"
        class="empty_dashboard_view_buttons"
      >
        <button
          v-for="deviceType in deviceTypes"
          :key="deviceType.id"
          class="dashboard_card_button"
          @click="selectDevice(deviceType.id)"
        >
          <img
            class="dashboard_card_button_img"
            :src="deviceType.icon"
            alt=""
          />
          <p class="dashboard_card_button_text">{{ $t(deviceType.name) }}</p>
        </button>
      </div>
      <div v-if="currentAddDeviceStep === 2">
        <form @submit.prevent="handleNext" novalidate>
          <AddDeviceForm
            :form="form"
            :errors="errors"
            @update:form="updateForm"
          />
        </form>
        <button @click="submitAddDevice(form)" class="button">
          {{ $t('NEW_DASHBOARD.NEW_DEVICE') }}
        </button>
      </div>
      <!--
      <div v-if="currentAddDeviceStep === 3">
        <div class="empty_dashboard_view_buttons">
          <button
            v-for="additionalDevice in availableAdditionalDevices"
            class="dashboard_card_button"
            :key="additionalDevice.id"
            @click="toggleAdditionalDevice(additionalDevice.name)"
            :class="{
              'card-selected': additionalDevices.includes(
                additionalDevice.name
              ),
              'card-deselected': !additionalDevices.includes(
                additionalDevice.name
              ),
            }"
          >
            <img
              class="dashboard_card_button_img"
              :src="additionalDevice.imageUrl"
              alt=""
            />
            <p class="dashboard_card_button_text">
              {{ $t(additionalDevice.name) }}
            </p>
          </button>
        </div>

        <button @click="submitAddDevice(form)" class="button">
          {{ $t('NEW_DASHBOARD.NEW_DEVICE') }}
        </button>
      </div>
      -->
    </b-modal>
  </div>
</template>

<script>
import DashboardTabs from "./DashboardTabs";
import AddDeviceForm from "./AddDeviceForm";
import { additional_devices } from "../libs/additional_devices";
import { deviceTypes } from "../libs/devices";

export default {
  name: "AddDeviceModal",
  components: {
    DashboardTabs,
    AddDeviceForm,
  },
  data() {
    return {
      currentAddDeviceStep: 1,
      addDeviceSteps: [
        { label: this.$t('NEW_DASHBOARD.MAIN_DEVICE_SELECTION'), value: 1 },
        { label: this.$t('NEW_DASHBOARD.INPUT_DATA'), value: 2 },
        // { label: this.$t('NEW_DASHBOARD.ADDITIONAL_DEVICE_SELECTION'), value: 3 },
      ],
      deviceTypes: Object.values(deviceTypes),
      form: {
        name: "",
        type: "",
        phone: "",
        imei: "",
        password: "",
        active: true,
      },
      errors: {},
      mainDevice: "",
      additionalDevices: [],
      availableAdditionalDevices: additional_devices,
    };
  },
  methods: {
    setActiveAddDeviceStep(step) {
      this.currentAddDeviceStep = step.value;
    },
    goBack() {
      if (this.currentAddDeviceStep > 1) {
        this.currentAddDeviceStep -= 1;
      }
    },
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
    submitAddDevice(form) {
      this.$emit("addDevice", {
        form: {
          ...form,
          additionalDevices: this.additionalDevices.join("&"),
          mainDevice: this.mainDevice,
        },
      });
      this.closeModal();
      this.resetForm();
    },
    selectDevice(device) {
      this.form.type = device;
      this.setActiveAddDeviceStep({ value: 2 });
    },
    toggleAdditionalDevice(device) {
      const index = this.additionalDevices.indexOf(device);
      if (index !== -1) {
        this.additionalDevices.splice(index, 1);
      } else {
        this.additionalDevices.push(device);
      }
    },
    updateForm(updatedForm) {
      this.form = updatedForm;
    },
    validate() {
      this.errors = {};

      if (!this.form.name) {
        this.errors.name = this.$t('NEW_DASHBOARD.NAME_REQUIRED');
      }

      if (!this.form.type) {
        this.errors.type = this.$t('NEW_DASHBOARD.TYPE_REQUIRED');
      }

      if (this.form.phone && !/^\+?[0-9\s\-()]*$/.test(this.form.phone)) {
        this.errors.phone = this.$t('NEW_DASHBOARD.INVALID_PHONE');
      }

      if (!this.form.imei) {
        this.errors.imei = this.$t('NEW_DASHBOARD.IMEI_REQUIRED');
      }

      if (!this.form.password) {
        this.errors.password = this.$t('NEW_DASHBOARD.PASSWORD_REQUIRED');
      }

      return Object.keys(this.errors).length === 0;
    },
    handleNext() {
      if (this.currentAddDeviceStep === 2) {
        if (this.validate()) {
          this.submitAddDevice(this.form);
        }
      } else {
        this.setActiveAddDeviceStep({ value: this.currentAddDeviceStep + 1 });
      }
    },
    resetForm() {
      this.currentAddDeviceStep = 1;
      this.mainDevice = "";
      this.form = {
        name: "",
        type: "",
        phone: "",
        imei: "",
        password: "",
        active: true,
      };
      this.errors = {};
      this.additionalDevices = [];
    },
  },
};
</script>

<style>
.empty_dashboard_view_buttons {
  padding: 1.15rem;
  display: flex;
  flex-wrap: wrap;
}
.dashboard_card_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  width: 217px;
  height: 217px;
  border-radius: 1rem;
  background: #ffffff;
  margin-right: 1rem;
  padding: 0.75rem;
  justify-content: space-between;
  border: 2px solid #44486026;
  transition: 0.3s;
  margin-bottom: 1rem;
}
.dashboard_card_button:hover {
  border-color: #64cff2;
}
.dashboard_card_button_img {
  object-fit: contain;
  width: 70%;
  height: 70%;
  flex: 1;
  padding: auto;
}
.card-selected {
  opacity: 1;
  border-color: #64cff2;
}
.card-deselected {
  opacity: 0.5;
}

.button {
  background-color: #0abbf7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.2s;
  opacity: 0.8;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.button:hover {
  background-color: #fff;
  border-color: #64cff2;
  color: #0abbf7;
}
</style>
