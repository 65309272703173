<template>
    <nav v-if="totalPages > 1" aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button
            class="page-link"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            Назад
          </button>
        </li>
  
        <li class="page-item" :class="{ active: currentPage === 1 }">
          <button class="page-link" @click="changePage(1)">1</button>
        </li>
  
        <li v-if="currentPage > 4" class="page-item">
          <span class="page-link">...</span>
        </li>
  
        <li
          v-for="page in getMiddlePages"
          :key="page"
          class="page-item"
          :class="{ active: currentPage === page }"
        >
          <button class="page-link" @click="changePage(page)">
            {{ page }}
          </button>
        </li>
  
        <li v-if="currentPage < totalPages - 3" class="page-item">
          <span class="page-link">...</span>
        </li>
  
        <li
          v-if="totalPages > 1"
          class="page-item"
          :class="{ active: currentPage === totalPages }"
        >
          <button class="page-link" @click="changePage(totalPages)">
            {{ totalPages }}
          </button>
        </li>
  
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button
            class="page-link"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === totalPages"
          >
            Вперед
          </button>
        </li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true,
      },
      totalPages: {
        type: Number,
        required: true,
      },
    },
    computed: {
      getMiddlePages() {
        const pages = [];
        const startPage = Math.max(2, this.currentPage - 2);
        const endPage = Math.min(this.totalPages - 1, this.currentPage + 2);
  
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
  
        return pages;
      },
    },
    methods: {
      changePage(page) {
        if (page >= 1 && page <= this.totalPages) {
          this.$emit('page-changed', page);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
  
  .page-item.disabled .page-link {
    pointer-events: none;
    background-color: #e9ecef;
  }
  </style>
  