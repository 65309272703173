<template>
  <div class="date-picker-container">
    <b-row>
      <b-col>
        <b-form-datepicker
          v-model="startDate"
          :locale="getLang()"
          :max="endDate"
          @input="onDateChange"
          :placeholder="$t('COMMON.START_DATE')"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-form-datepicker
          lang="es"
          v-model="endDate"
          :locale="getLang()"
          :min="startDate"
          @input="onDateChange"
          :placeholder="$t('COMMON.END_DATE')"
        ></b-form-datepicker>
      </b-col>
      <b-col :md="v.width" :key="k" v-for="(v, k) in filters">
        <b-form-input
          type="text"
          v-if="v.type == 'text'"
          :placeholder="v.placeholder"
          v-model="v.value"
        />
      </b-col>
      <b-col v-if="showForecast" class="d-flex align-items-center">
        <b-form-checkbox
          v-model="enableForecast"
          switch
          @change="onToggleForecast"
        >
          {{ $t("DEVICE.FORECAST") }}
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <b-button @click="handleCalculate">{{
          $t("COMMON.CALCULATE")
        }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormDatepicker, BButton, BRow, BCol } from "bootstrap-vue";

export default {
  name: "DatePicker",
  components: {
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
  },
  props: {
    onCalculate: {
      type: Function,
      required: true,
    },
    initialStartDate: {
      type: Date,
      default: null,
    },
    initialEndDate: {
      type: Date,
      default: null,
    },
    showForecast: {
      type: Boolean,
      default: false,
    },
    enableForecast: {
      type: Boolean,
      default: false,
    },
    onToggleForecast: {
      type: Function,
      required: true,
    },
    filters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      startDate: this.initialStartDate ? new Date(this.initialStartDate) : null,
      endDate: this.initialEndDate ? new Date(this.initialEndDate) : null,
    };
  },
  methods: {
    getLang() {
      return localStorage.getItem("language") || "en";
    },
    onDateChange() {},
    handleCalculate() {
      if (this.startDate && this.endDate) {
        const formattedStartDate = this.formatToISOString(this.startDate);
        const formattedEndDate = this.formatToISOString(this.endDate);
        this.onCalculate(formattedStartDate, formattedEndDate);
      } else {
        alert("Пожалуйста, выберите обе даты");
      }
    },
    formatToISOString(date) {
      const d = new Date(date);
      return d.toISOString();
    },
  },
};
</script>

<style scoped>
.date-picker-container {
  margin-bottom: 20px;
  flex: 1;
  max-width: 90%;
}
.forecast-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
