<template>
  <div ref="forPng" @resize="onResize">
    <div class="widget-header">
      <h2 class="device-name">{{ $t(widgetList?.[widget.type]?.name) || widget.name }} -- {{ widget.deviceName }}</h2>
      <div class="download-remove-container">
        <div v-if="!widget.isLep" class="download-dropdown">
          <button @click="downloadFormat = !downloadFormat" class="remove-btn">
            <img src="media/svg/buttons/download.svg" alt="" />
          </button>
          <div v-if="downloadFormat" class="dropdown-content">
            <a @click="downloadData('csv')">CSV</a>
            <a @click="downloadData('pdf')">PDF</a>
            <a @click="downloadData('xlsx')">XLSX</a>
            <a @click="downloadData('png')">PNG</a>
          </div>
        </div>
        <ConfirmDeleteModal :item-id="widget.i" :on-delete="remove">
          <template #button>
            <button class="remove-btn">
              <img src="media/svg/buttons/delete.svg" alt="" />
            </button>
          </template>
        </ConfirmDeleteModal>
      </div>
    </div>

    <DatePicker :onCalculate="handleDateChange" :initialStartDate="date.startDate" :initialEndDate="date.endDate"
      :show-forecast="isForecastWidget" :enable-forecast="enableForecast" :on-toggle-forecast="handleToggleForecast"
      :filters="getFilters()" />

    <div v-if="lineData.length === 0 && !loading && !widget.isLep" class="no-data-message">
      {{ $t('NEW_DASHBOARD.NO_DATA_FOR_PERIOD') }}
    </div>
    <div ref="chartContent" v-else-if="viewGraph" class="chart-content">
      <div v-if="isContentTooNarrow" class="too-small-message">
        Размер виджета слишком мал. Увеличьте размер для корректного
        отображения.
      </div>
      <div v-if="loading" class="loader-container">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <LineChart v-if="!loading && widget.type === 'Linear'" :data="lineData" :ref="'chart-' + widget.i"
        :widget="widget" :fetchData="loadChartData" @update-widget="$emit('update-widget', $event)" />
      <GasLinear v-if="!loading && widget.type === 'GasLinear'" :data="lineData" :ref="'chart-' + widget.i"
        :widget="widget" :fetchData="loadChartData" :pdks="pdks" @update-widget="$emit('update-widget', $event)" />
      <BarChart v-if="!loading && widget.type === 'TemperatureChart'" :raw-data="lineData" :ref="'chart-' + widget.i"
        :widget="widget" :fetchData="loadChartData" @update-widget="$emit('update-widget', $event)" />
      <TableComponent v-if="!loading && widget.type === 'Table'" class="table-container" :raw-data="lineData"
        :ref="'chart-' + widget.i" :widget="widget" :fetchData="loadChartData"
        @update-widget="$emit('update-widget', $event)" />
      <TemperatureTable v-if="!loading && widget.type === 'TemperatureTable'" class="table-container"
        :raw-data="lineData" :ref="'chart-' + widget.i" :widget="widget" :fetchData="loadChartData"
        @update-widget="$emit('update-widget', $event)" />
      <AccidentSynchronousOscillograms v-if="widget.type === 'ASO'" :widget="widget" :startDate="date.startDate"
        :endDate="date.endDate" :filters="scatFilters"/>
      <ScatIndications v-if="widget.type === 'ScatIndications'" :widget="widget" :startDate="date.startDate"
        :endDate="date.endDate" :filters="scatFilters"/>
      <PolarAreaChart v-if="!loading && widget.type === 'WindChart'" :data="lineData" :ref="'chart-' + widget.i"
        :widget="widget" :fetchData="loadChartData" @update-widget="$emit('update-widget', $event)" />
    </div>
  </div>
</template>

<script>
import { API_REQUEST } from "../../../../core/services/store/api.module";
import axios from "axios";
import LineChart from "@/view/content/new_dashboard/charts/LineChart";
import GasLinear from "@/view/content/new_dashboard/charts/GasLinear";
import BarChart from "@/view/content/new_dashboard/charts/BarChart";
import PolarAreaChart from "@/view/content/new_dashboard/charts/PolarAreaChart";
import ApiService from "../../../../core/services/api.service";
import DatePicker from "./DatePicker.vue";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import TableComponent from "./TableComponent";
import TemperatureTable from "./TemperatureTable";
import AccidentSynchronousOscillograms from "./AccidentSynchronousOscillograms";
import ScatIndications from "./ScatIndications";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import { extractWidgets } from "../libs/widget-list";

export default {
  name: "ChartWrapper",
  components: {
    LineChart,
    BarChart,
    PolarAreaChart,
    DatePicker,
    ConfirmDeleteModal,
    TableComponent,
    TemperatureTable,
    AccidentSynchronousOscillograms,
    ScatIndications,
    GasLinear
  },
  props: {
    editMode: {
      type: Boolean,
    },
    widget: {
      type: Object,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
    isForecastWidget: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      widgetList: extractWidgets(),
      downloadFormat: false,
      rowHeight: 30,
      columnWidth: 30,
      updateInterval: null,
      rawData: {
        newData: [],
        filteredParams: [],
      },
      pdks: {},
      lineData: [],
      loading: false,
      parametesRequestParams: {
        parameterIds: [],
        teamId: ApiService.teamData.value,
        realtime: false,
        deviceId: this.widget.deviceId,
        with_forecast: false,
      },
      startDate: this.widget.startDate || null,
      endDate: this.widget.endDate || null,
      enableForecast: false,
      scatFilters: {
        sensorId: {
          type: "text",
          value: "",
          placeholder: "Номер датчика",
          width: 2
        }
      }
    };
  },
  watch: {
    enableForecast() {
      this.loadChartData();
    },
  },
  computed: {
    date() {
      if (!this.widget.startDate && !this.widget.endDate) {
        return {
          startDate: this.getPreviousDate(this.widget.interval || "MONTH"),
          endDate: new Date(),
        };
      }
      return {
        startDate: new Date(this.widget.startDate),
        endDate: new Date(this.widget.endDate),
      };
    },
    viewGraph() {
      return this.editMode ? false : true;
    },
    isContentTooNarrow() {
      const chartContent = this.$refs.chartContent;
      return chartContent ? chartContent.clientWidth < 200 : false;
    },
    containerStyle() {
      return {
        height: this.calculateHeight(),
        width: this.calculateWidth(),
      };
    },
  },
  methods: {
    getFilters() {
      switch (this.widget.type) {
        case "ScatIndications":
          return this.scatFilters;
        case "ASO":
          return this.scatFilters;
        default:
          return {}
      }
    },
    handleToggleForecast(value) {
      this.enableForecast = value;
    },
    downloadData(format) {
      if (format === "csv") this.downloadCSV();
      else if (format === "pdf") this.downloadPDF();
      else if (format === "xlsx") this.downloadXLSX();
      else if (format === "png") {

        this.downloadFormat = false;
        setTimeout(() => {
          this.downloadPNG();
        })
      }
      this.downloadFormat = false;
    },
    async downloadPNG() {
      try {
        const element = this.$refs.forPng;
        const canvas = await html2canvas(element, {
          // scale: 2,
          useCORS: true,
        });

        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");

        const fileName = `${this.widget.deviceName || "device"
          }_${this.formatDate(this.date.startDate)}_to_${this.formatDate(
            this.date.endDate
          )}.png`;
        link.download = fileName;

        link.click();
      } catch (error) {
        console.error("Ошибка при создании скриншота:", error);
      }
    },
    downloadCSV() {
      const headers = [
        "Дата",
        ...Object.keys(this.lineData[0]?.parameterValues || {}),
      ].join(",");

      const rows = this.lineData.map((item) => {
        const date = new Date(item.date);
        const formattedDate =
          [
            String(date.getDate()).padStart(2, "0"), // День
            String(date.getMonth() + 1).padStart(2, "0"), // Месяц
            String(date.getFullYear()).slice(-2), // Год
          ].join("-") +
          " " +
          [
            String(date.getHours()).padStart(2, "0"), // Часы
            String(date.getMinutes()).padStart(2, "0"), // Минуты
            String(date.getSeconds()).padStart(2, "0"), // Секунды
          ].join(":");

        return [formattedDate, ...Object.values(item.parameterValues)].join(
          ","
        );
      });

      const csvContent = [headers, ...rows].join("\n");

      const startDate = new Date(this.date.startDate);
      const endDate = new Date(this.date.endDate);
      const formattedStartDate = [
        String(startDate.getDate()).padStart(2, "0"),
        String(startDate.getMonth() + 1).padStart(2, "0"),
        String(startDate.getFullYear()).slice(-2),
      ].join("-");
      const formattedEndDate = [
        String(endDate.getDate()).padStart(2, "0"),
        String(endDate.getMonth() + 1).padStart(2, "0"),
        String(endDate.getFullYear()).slice(-2),
      ].join("-");

      const fileName = `${this.widget.deviceName || "device"
        }_${formattedStartDate}_to_${formattedEndDate}.csv`;

      // Создаем Blob для CSV и URL для его скачивания
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadPDF() {
      const doc = new jsPDF();
      const headers = [
        "Дата",
        ...Object.keys(this.lineData[0]?.parameterValues || {}),
      ];
      const rows = this.lineData.map((item) => {
        const date = new Date(item.date);
        const formattedDate =
          [
            String(date.getDate()).padStart(2, "0"),
            String(date.getMonth() + 1).padStart(2, "0"),
            String(date.getFullYear()).slice(-2),
          ].join("-") +
          " " +
          [
            String(date.getHours()).padStart(2, "0"),
            String(date.getMinutes()).padStart(2, "0"),
            String(date.getSeconds()).padStart(2, "0"),
          ].join(":");
        return [formattedDate, ...Object.values(item.parameterValues)];
      });

      // Используем autoTable для создания таблицы
      doc.autoTable({
        head: [headers],
        body: rows,
      });

      const fileName = `${this.widget.deviceName || "device"}_${this.formatDate(
        this.date.startDate
      )}_to_${this.formatDate(this.date.endDate)}.pdf`;
      doc.save(fileName);
    },
    downloadXLSX() {
      const headers = [
        "Дата",
        ...Object.keys(this.lineData[0]?.parameterValues || {}),
      ];
      const rows = this.lineData.map((item) => {
        const date = new Date(item.date);
        const formattedDate =
          [
            String(date.getDate()).padStart(2, "0"),
            String(date.getMonth() + 1).padStart(2, "0"),
            String(date.getFullYear()).slice(-2),
          ].join("-") +
          " " +
          [
            String(date.getHours()).padStart(2, "0"),
            String(date.getMinutes()).padStart(2, "0"),
            String(date.getSeconds()).padStart(2, "0"),
          ].join(":");
        return [formattedDate, ...Object.values(item.parameterValues)];
      });

      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      const fileName = `${this.widget.deviceName || "device"}_${this.formatDate(
        this.date.startDate
      )}_to_${this.formatDate(this.date.endDate)}.xlsx`;
      XLSX.writeFile(workbook, fileName);
    },

    formatDate(date) {
      const d = new Date(date);
      return [
        String(d.getDate()).padStart(2, "0"),
        String(d.getMonth() + 1).padStart(2, "0"),
        String(d.getFullYear()).slice(-2),
      ].join("-");
    },

    onError(error) {
      let message = this.$t("COMMON.UNKNOWN_ERROR");

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      } else if (error.message) {
        message = error.message;
      } else if (error.request) {
        message = this.$t("COMMON.NETWORK_ERROR");
      }

      this.$bvToast.toast(message, {
        title: this.$t("COMMON.ERROR"),
        variant: "danger",
        autoHideDelay: 5000,
      });

      this.isLoading = false;
    },

    onResize(event) {
      this.$emit("resize", event, this.widget);
    },

    calculateHeight() {
      return `${this.widget.h * this.rowHeight}px`;
    },

    calculateWidth() {
      return `${this.widget.w * this.columnWidth}px`;
    },

    async loadDevice({ id }) {
      if (this.widget.isLep) {
        return;
      }
      try {
        const response = await this.$store.dispatch(API_REQUEST, {
          resource: `/api/device/${id}`,
          requestType: "GET",
          requestParams: { enrich: true },
        });
        return response;
      } catch (error) {
        this.onError(error);
      }
    },

    async loadChartData(
      startDate = this.widget?.startDate,
      endDate = this.widget?.endDate
    ) {
      if (this.widget.isLep) {
        return;
      }
      this.loading = true;

      try {
        const response = await this.loadDevice({ id: this?.widget.deviceId });
        if (response.typeId === "297e9ce2-9134-4004-b67f-21c1683aaede") {
          let params = {
            ...this.parametesRequestParams,
          };
          if (!startDate && !endDate) {
            params.startDate = this.date.startDate;
            params.endDate = this.date.endDate;
          } else {
            params.startDate = startDate;
            params.endDate = endDate;
          }
          params.groupType = "DAY";
          const scatDeviceResponse = await axios.get(
            "api/oscillogram/skat-measurements",
            {
              params,
            }
          );

          if (this.widget?.isScat && !this.widget.initialized) {
            const disabledOptions = Object.keys(
              scatDeviceResponse.data[0]?.parameterValues || {}
            ).filter((p) => p !== "u_Battery");

            this.$emit("update-widget", {
              ...this.widget,
              disabledOptions,
              initialized: true,
            });

            this.widget.disabledOptions = disabledOptions;
          }
          this.lineData = scatDeviceResponse.data || [];
          return;
        }

        const parameterIdsToRequest = response.parameterMappings
          .filter((mapping) =>
            Object.keys(this.widget.parameters).includes(mapping.code)
          )
          .map((mapping) => {
            this.pdks[mapping.code] = mapping.gasConcentrationThreshold || 0
            return mapping.parameterId
          });



        let params = {
          ...this.parametesRequestParams,
          parameterIds: parameterIdsToRequest,
          with_forecast: this.enableForecast,
        };

        if (!startDate && !endDate) {
          params.startDate = this.date.startDate;
          params.endDate = this.date.endDate;
        } else {
          params.startDate = startDate;
          params.endDate = endDate;
        }
        params.groupType = this.widget.groupTypeName;
        if (
          this.widget.type === "TemperatureChart" ||
          this.widget.type === "TemperatureTable"
        )
          params.groupType = "HOUR";

        const { data } = await axios.get("api/measure/by-device", {
          params,
        });

        this.lineData = data || [];
      } catch (error) {
        this.onError(error);
      } finally {
        this.loading = false;
      }
    },

    handleDateChange(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.parametesRequestParams.startDate = startDate;
      this.parametesRequestParams.endDate = endDate;
      this.$emit("update-widget", {
        ...this.widget,
        startDate,
        endDate,
      });
      this.loadChartData(startDate, endDate);
    },

    getPreviousDate(interval = "MONTH") {
      const today = new Date();
      const resultDate = new Date(today);

      switch (interval.toUpperCase()) {
        case "DAY":
          resultDate.setDate(today.getDate() - 1);
          break;
        case "WEEK":
          resultDate.setDate(today.getDate() - 7);
          break;
        case "MONTH":
          resultDate.setMonth(today.getMonth() - 1);
          break;
        case "YEAR":
          resultDate.setFullYear(today.getFullYear() - 1);
          break;
        default:
          throw new Error(
            "Неверно указан интервал. Допустимые значения: DAY, WEEK, MONTH, YEAR."
          );
      }
      return resultDate;
    },

    handleClickOutside(event) {
      const dropdown = this.$el.querySelector(".download-dropdown");
      if (dropdown && !dropdown.contains(event.target)) {
        this.downloadFormat = false;
      }
    },
  },
  mounted() {
    this.loadChartData();
    if (this.widget.autoUpdate) {
      this.updateInterval = setInterval(() => {
        this.loadChartData();
      }, 600000);
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.device-name {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #181d31;
}

.widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(181, 181, 194, 0.5);
  padding-bottom: 20px;
  padding-top: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 18px;
  color: #888;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.too-small-message {
  font-size: 14px;
  color: #d9534f;
  background-color: #f2dede;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
}

.download-dropdown {
  position: relative;
  display: inline-block;
}

.download-btn {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.download-remove-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: -100px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.device-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
